import * as React from 'react';
import { fabric } from "fabric";
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit,  setLoading } from '../../actions/main'
import { setSnackMsg, setOpenSnack } from '../../actions/ui'
import { setBoothHistory } from '../../actions/booth_history'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import rgbHex from "rgb-hex";
import axios from 'axios';
import qs, { parse } from 'qs'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, setObjectBG, resizeBooth, addText, checkifrect, px2unit, unit2Px, checkIfSelectable, unit2PxBig, px2unitBig, checkifrectbooth} from '../../utils/CanvasTool'

import { getDefaultGroup } from '../../utils/Common'
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";

import { parseToken, convertRgbHex } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'
import BigNumber from "bignumber.js";


const emails = ['username@gmail.com', 'user02@gmail.com'];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SetBoothModal(props) {
  
  let query = useQuery();
  // const [snackMsg, setSnackMsg] = useState('');
  // const [openSnack, setOpenSnack] = useState(false);
  const [activeObject, setActiveObject] = React.useState(null);
  const [priceCheck , setPriceCheck] = React.useState([]);
  const [premiumCheck , setPremiumCheck] = React.useState('');
  const [discountCheck, setDiscountCheck] = React.useState('');
  const [boothName , setBoothName] = React.useState('');
  const [boothWidth , setBoothWidth] = React.useState(0);
  const [boothHeight , setBoothHeight] = React.useState(0);
  const [boothLeft , setBoothLeft] = React.useState(0);
  const [boothTop , setBoothTop] = React.useState(0);
  const [boothAngle, setBoothAngle]  = React.useState(0);
  const [boothGroup , setBoothGroup] = React.useState('');
  const [labelPosition , setLabelPosition] = React.useState('bottom-left');
  const [holdBooth, setHoldBooth] = React.useState(false);  
  const [privateNote, setPrivateNote] = React.useState('');
  const [publicNote, setPublicNote] = React.useState('');
  const [inviteCode, setInviteCode] = React.useState('');
  const [boothType, setBoothType] = React.useState('');

  
  var active_booth_file = props.main.active_booth_file
  var mainCanvas = props.main.mainCanvas
  const { onClose, onOk , open } = props;
  var groupOptions = props.main.groupOptions
  

  // const handleCloseSnack = ()=>{
  //   setOpenSnack(false);
  // }

  const onSet = ()=>{
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
    }

    if(activeObject && activeObject.type=="activeSelection"){
      var objects = activeObject.getObjects()
      for(var i = 0; i < objects.length; i++ ){
        if(objects[i].class!="booth") continue;
        objects[i].set({      
          angle:boothAngle,
          // priceCheck: priceCheck,
          // premiumCheck: (premiumCheck),
          // discountCheck: discountCheck,
          boothGroup: boothGroup,
          labelPosition: labelPosition,
          boothType:boothType,
          invite_code:inviteCode,
          modified: true
        })
        var textObjects = objects[i].getObjects('text');
        for(var k = 0; k< textObjects.length; k++){
          objects[i].remove(textObjects[k])
        }
        addText(mainCanvas, objects[i], (objects[i].company?((objects[i].company+"").trim()+"\n"):"")+""+objects[i].booth_number)
        var premiumCheck = objects[i].get('premiumCheck')
        var priceCheck = objects[i].get('priceCheck')
        var discountCheck = objects[i].get('discountCheck')
        var exhibitors = objects[i].exhibitors
        var bgColor = "#dadada"
        {
          for(var k = 0; k < groupOptions.length; k++){
              if(groupOptions[k].KEY_ID == boothGroup){
                  var groupOption = groupOptions[k]
                  
                  if(objects[i].sold == '1'){
                    bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                  }
                  else if((priceCheck && priceCheck.length > 0) || (premiumCheck && premiumCheck.length > 0) || (discountCheck && discountCheck.length > 0)){
                      bgColor = convertRgbHex(groupOption['AVAILABLE']);
                  }
              }
          }
        }

        setObjectBG(mainCanvas, objects[i], bgColor)
        objects[i].set({        
          deselectedColor: bgColor
        })
      }
    }
    else if(activeObject && activeObject.class=="booth"){
      if(!boothName || boothName == ''){
        props.setOpenSnack(true);           
        props.setSnackMsg("Booth name can not be empty");
        return;
      }
      var trim_boothName = boothName.trim()
      var firstObjects = props.main.firstMapContent&&props.main.firstMapContent.objects?props.main.firstMapContent.objects:[]
      var objects = [...firstObjects]
      for(var i = 0; i < objects.length; i++){
        if(objects[i].KEY_ID != activeObject.KEY_ID){
          if(objects[i].booth_number == trim_boothName && trim_boothName&&objects[i].class=="booth"){ 
            props.setOpenSnack(true);           
            props.setSnackMsg("Booth Number exists already");
            return;
          }
        }
      }      
      objects = [...props.main.mapRemoveObjects] 
      for(var i = 0; i < objects.length; i++){
        if(objects[i].KEY_ID != activeObject.KEY_ID){
          if(checkIfSelectable(objects[i])&&objects[i].booth_number == trim_boothName && trim_boothName&&objects[i].class=="booth"){ 
            props.setOpenSnack(true);           
            props.setSnackMsg("Booth Number exists already");
            return;
          }
        }
      }
      objects = mainCanvas.getObjects();
      for(var i = 0; i < objects.length; i++){
        if(checkIfSelectable(objects[i])&& objects[i].KEY_ID != activeObject.KEY_ID&&objects[i].class=="booth"){
          if(objects[i].booth_number == trim_boothName && trim_boothName){ 
            props.setOpenSnack(true);           
            props.setSnackMsg("Booth Number exists already");
            return;
          }
        }
      }
      if(!boothWidth || boothWidth*1 ==0 || boothWidth == ''){
        props.setOpenSnack(true);           
        props.setSnackMsg("Booth width can not be empty");
        return;
      }
      if(!boothHeight || boothHeight*1 ==0 || boothHeight == ''){
        props.setOpenSnack(true);           
        props.setSnackMsg("Booth height can not be empty");
        return;
      }
      if(!boothLeft || boothLeft*1 ==0 || boothLeft == ''){
        props.setOpenSnack(true);           
        props.setSnackMsg("Position X can not be empty");
        return;
      }
      if(!boothTop || boothTop*1 ==0 || boothTop == ''){
        props.setOpenSnack(true);           
        props.setSnackMsg("Position Y can not be empty");
        return;
      }
      var newWidth = unit2Px(boothWidth, convertRateX, mainCanvas.mapUnit)
      var newHeight = unit2Px(boothHeight, convertRateX, mainCanvas.mapUnit)
      // if(mainCanvas.convertUnit == 'mm'){
      //   newWidth*=1000
      //   newHeight*=1000
      //   width/=1000
      //   height/=1000
      // }
      activeObject.set({
        booth_number: trim_boothName,
        labelPosition: labelPosition,
        boothGroup: boothGroup
      })
      mainCanvas.renderAll()
      var orgObject = activeObject.toDatalessObject()
      
      var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
      var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
      if(active_booth_file.version == 2){
        activeObject.set({
          dwg_left: boothLeft, 
          dwg_top: boothTop,
          left: unit2PxBig((new BigNumber(boothLeft)).minus((new BigNumber(mainCanvas.convertOffsetX)).dividedBy(mainCanvas.mapUnit == 'feet'?12:1)), convertRateX, mainCanvas.mapUnit).plus(50).plus(mapOffsetX).toNumber(),
          top: unit2PxBig(new BigNumber(mainCanvas.convertOffsetY).dividedBy(mainCanvas.mapUnit == 'feet'?12:1).minus(boothTop), convertRateX, mainCanvas.mapUnit).plus(50).plus(mapOffsetY).toNumber()
        })    
      }
      else{
        activeObject.set({
          left: unit2Px(boothLeft, convertRateX, mainCanvas.mapUnit) + 50 + mapOffsetX,
          top: unit2Px(boothTop, convertRateX, mainCanvas.mapUnit) + 50 + mapOffsetY
        })    
      }

      var resizedObject = activeObject
      var resizable = true;
      if(activeObject.class1 == "custom-booth" || !checkifrectbooth(activeObject)){
        resizable = false;
      }
      var objects = activeObject.getObjects('polygon');
      if(objects.length){
        var polygon = objects[0]
        if(polygon.points?.length < 4){
          resizable = false;
        }
      }
      if(resizable){
        resizedObject = resizeBooth(mainCanvas, activeObject, newWidth, newHeight)
      }
      else
      {
        var textObjects = resizedObject.getObjects('text');
        for(var k = 0; k< textObjects.length; k++){
          resizedObject.remove(textObjects[k])
        }
        addText(mainCanvas, resizedObject, (resizedObject.company&&(resizedObject.company+"").trim()!=""?((resizedObject.company+"").trim()+"\n"):"")+""+resizedObject.booth_number)
      }
      resizedObject.set({
        dwg_width: boothWidth, 
        dwg_height: boothHeight 
      })
      // activeObject.clipPath = new fabric.Rect({
      //   width: newWidth,
      //   height: newHeight,
      //   top: - (newHeight) / 2,
      //   left: - (newWidth) / 2
      // });

      // var boothGroup = activeObject.boothGroup;
      var premiumCheck = activeObject.get('premiumCheck')
      var priceCheck = activeObject.get('priceCheck')
      var discountCheck = activeObject.get('discountCheck')
      var exhibitors = activeObject.exhibitors
      var bgColor = "#dadada"
      {
        for(var k = 0; k < groupOptions.length; k++){
            if(groupOptions[k].KEY_ID == boothGroup){
                var groupOption = groupOptions[k]
                if(holdBooth){
                  bgColor = convertRgbHex(groupOption['RESERVED']);
                }
                else if(resizedObject.sold == '1'){
                  bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                }
                else if((priceCheck && priceCheck.length > 0) || (premiumCheck && premiumCheck.length > 0) || (discountCheck && discountCheck.length > 0)){
                    bgColor = convertRgbHex(groupOption['AVAILABLE']);
                }
            }
        }
      }

      setObjectBG(mainCanvas, resizedObject, bgColor)
      resizedObject.set({        
        angle:boothAngle,
        modified: true,
        deselectedColor: bgColor,
        holdBooth: holdBooth?'1':false,
        privateNote:privateNote,
        publicNote:publicNote,
        boothType:boothType,
        invite_code:inviteCode
      })
      props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'updated', orgObjects:[orgObject], target:resizedObject}})
    }
    mainCanvas.renderAll()
    onOk(activeObject);
  }
  

  
  useEffect(() => {
    var mainCanvas = props.main.mainCanvas
    if(open && mainCanvas){      
      var activeObject = mainCanvas.getActiveObject();

      if(activeObject.type == "group" && (activeObject.class=="booth" || activeObject.class=="booth-group")){
        var convertRateX= 1
        if(mainCanvas.convertRateX) {
            var convertRateX= Math.abs(mainCanvas.convertRateX)
        }

        setBoothName(activeObject.booth_number)
        var decimal = 1;
        // if((activeObject.width)/feetUnitSize < 1) decimal = 2
        //1326.3833333333334, 12.685166666666667
        var mapOffsetX = (mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0)
        var mapOffsetY = (mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0)
        if(active_booth_file.version == '2'){
          var leftTxt = px2unitBig((new BigNumber(activeObject.left)).minus(50), convertRateX, mainCanvas.mapUnit).plus((new BigNumber(mainCanvas.convertOffsetX)).dividedBy(mainCanvas.mapUnit == 'feet'?12:1)).minus(mapOffsetX)
          var topTxt = (new BigNumber(mainCanvas.convertOffsetY)).dividedBy(mainCanvas.mapUnit == 'feet'?12:1).minus(px2unitBig((new BigNumber(activeObject.top)).minus(50),convertRateX, mainCanvas.mapUnit).minus(mapOffsetY))
          // setBoothWidth(activeObject.dwg_width)
          // setBoothHeight(activeObject.dwg_height)
          let width = px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit)
          let height = px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit)
          // if(mainCanvas.convertUnit == 'mm'){
          //   leftTxt/=1000
          //   topTxt/=1000
          //   width/=1000
          //   height/=1000
          // }
          setBoothWidth(width.toFixed(decimal).replace(/\.0$/, ""))
          setBoothHeight(height.toFixed(decimal).replace(/\.0$/, ""))
          setBoothLeft(leftTxt.toFixed(12))
          setBoothTop(topTxt.toFixed(12))
        }
        else{
          let width = px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit)
          let height = px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit)
          var leftTxt = (px2unit(activeObject.left-50, convertRateX, mainCanvas.mapUnit)-mapOffsetX)
          var topTxt = (px2unit(activeObject.top-50, convertRateX, mainCanvas.mapUnit)-mapOffsetY)
          // if(mainCanvas.convertUnit == 'mm'){
          //   leftTxt/=1000
          //   topTxt/=1000
          //   width/=1000
          //   height/=1000
          // }
          setBoothWidth(width.toFixed(decimal).replace(/\.0$/, ""))
          setBoothHeight(height.toFixed(decimal).replace(/\.0$/, ""))
          setBoothLeft(leftTxt.toFixed(6))
          setBoothTop(topTxt.toFixed(6))
        }
        setBoothGroup(activeObject.boothGroup?activeObject.boothGroup:"")
        setLabelPosition(activeObject.labelPosition?activeObject.labelPosition:"bottom-left")
        
        setBoothAngle(activeObject.angle)
        setHoldBooth(activeObject.holdBooth)
        setPrivateNote(activeObject.privateNote?activeObject.privateNote:"")
        setPublicNote(activeObject.publicNote?activeObject.publicNote:"")
        setInviteCode(activeObject.invite_code?activeObject.invite_code:'')
        setBoothType(activeObject.boothType?activeObject.boothType:'')

        var tmp1 = activeObject.get('premiumCheck')
        if(tmp1){
          setPremiumCheck(tmp1)
        }
        else{
          setPremiumCheck([])
        }
        var tmp2 = activeObject.get('priceCheck')
        if(tmp2){
          setPriceCheck(tmp2)
        }
        else{
          setPriceCheck([])
        }
        var tmp3 = activeObject.get('discountCheck')
        if(tmp3){
          setDiscountCheck(tmp3)
        }
        else{
          setDiscountCheck([])
        }
      }
      else if(activeObject.type == "activeSelection"){
        var objects = activeObject.getObjects()
        var arr1 = [];
        for(var i = 0; i < objects.length; i++ ){
          if(objects[i].boothGroup){
            arr1.push(objects[i].boothGroup)
          }
        }
        var mf = 1;
        var m = 0;
        var maxGroup
        for (var i=0; i<arr1.length; i++)
        {
                for (var j=i; j<arr1.length; j++)
                {
                        if (arr1[i] == arr1[j])
                        m++;
                        if (mf<m)
                        {
                          mf=m; 
                          maxGroup = arr1[i];
                        }
                }
                m=0;
        }
        setBoothGroup(maxGroup?maxGroup:null)
        setPremiumCheck([])
        setDiscountCheck([])
        setPriceCheck([])
      }
      else{        
        setBoothGroup(null)
        setPremiumCheck([])
        setDiscountCheck([])
        setPriceCheck([])
      }

      setActiveObject(activeObject)
    }
  }  , [open])
  
  const onChangeName = (e)=>{
    setBoothName(e.target.value)
  }
  console.log("boothGroup", boothGroup)
  
  return (
    
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'setbooth-dialog-title'}}
      aria-labelledby="setbooth-dialog-title"
      fullWidth={true}
      maxWidth={"md"} 
      open={open}
    >
      <DialogTitle id='setbooth-dialog-title'>Booth Setup</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container spacing={2} style={{marginTop:15}}>
            <Grid item xs={6}>
              <FormControl variant="outlined"
                size="small" fullWidth style={{marginTop:'7px'}}>
                <InputLabel shrink={true}>Group</InputLabel>
                <Select
                  input={<OutlinedInput notched label="Group" />}
                  fullWidth
                  size="small"
                  value={boothGroup?boothGroup:''}
                  onChange={(e)=>{setBoothGroup( e.target.value); console.log(e.target.value)}}
                  displayEmpty
                >
                  <MenuItem  value="">&nbsp;</MenuItem>
                  {
                    groupOptions.map((row, index)=>{
                      return(
                        <MenuItem key={index} value={row.KEY_ID}>{row.TITLE}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>              
            </Grid>
            <Grid item xs={6}>
              {activeObject && activeObject.type !='activeSelection' &&
              <TextField
                name="boothName"
                margin="dense"
                label="Booth Name"
                type="name"
                fullWidth
                variant="outlined"
                size="small"
                value={boothName}
                onChange={onChangeName}
                disabled = {activeObject?.sold}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              }
            </Grid>
            {activeObject && activeObject.type !='activeSelection' &&
            <>
            {checkifrectbooth(activeObject) && 
            <Grid item xs={6}>
              <TextField
                name="boothWidth"
                margin="dense"
                label="Width"
                type="number"
                fullWidth
                variant="outlined"
                size="small"
                value={boothWidth}
                onChange={(e)=>{setBoothWidth(e.target.value)}}
                // disabled = {activeObject?.sold}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            }
            {checkifrectbooth(activeObject) && 
            <Grid item xs={6}>
              <TextField
                name="boothHeight"
                margin="dense"
                label="Height"
                type="number"
                fullWidth
                variant="outlined"
                size="small"
                value={boothHeight}
                onChange={(e)=>{setBoothHeight(e.target.value)}}
                // disabled = {activeObject?.sold}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            }
            <Grid item xs={6}>
              <TextField
                name="X"
                margin="dense"
                label="X"
                type="number"
                fullWidth
                variant="outlined"
                size="small"
                value={boothLeft}
                onChange={(e)=>{setBoothLeft(e.target.value)}}
                disabled = {activeObject?.sold}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="Y"
                margin="dense"
                label="Y"
                type="number"
                fullWidth
                variant="outlined"
                size="small"
                value={boothTop}
                onChange={(e)=>{setBoothTop(e.target.value)}}
                disabled = {activeObject?.sold}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            </>
            }
            <Grid item xs={6}>
              <TextField
                name="angle"
                margin="dense"
                label="Angle (degree) "
                type="name"
                fullWidth
                variant="outlined"
                size="small"
                value={boothAngle}
                onChange={(e)=>{setBoothAngle(e.target.value)}}
                disabled = {activeObject?.sold}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
            <Grid item xs={6}>
              <FormControl variant="outlined"
                size="small" fullWidth style={{marginTop:'7px'}}>
                <InputLabel shrink={true}>Label Position</InputLabel>
                <Select
                  input={<OutlinedInput notched label="Label Position" />}
                  fullWidth
                  size="small"
                  value={labelPosition?labelPosition:''}
                  onChange={(e)=>{setLabelPosition( e.target.value); console.log(e.target.value)}}
                >
                  <MenuItem value='bottom-left'>Bottom Left</MenuItem>
                  <MenuItem value='bottom-right'>Bottom Right</MenuItem>
                  <MenuItem value='center'>Bottom Center</MenuItem>
                  <MenuItem value='top-left'>Top Left</MenuItem>
                  <MenuItem value='top-right'>Top Right</MenuItem>
                  <MenuItem value='top-center'>Top Center</MenuItem>
                </Select>
              </FormControl>              
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="invite_code"
                margin="dense"
                label="Invite Code"
                type="name"
                fullWidth
                variant="outlined"
                size="small"
                value={inviteCode}
                onChange={(e)=>{setInviteCode(e.target.value)}}
                // onInput = {(e) =>{
                //   e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,15)
                // }}
                inputProps={{ maxLength: 15 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined"
                size="small" fullWidth style={{marginTop:'7px'}}>
                <InputLabel shrink={true}>Booth Type</InputLabel>
                <Select
                  input={<OutlinedInput notched label="Booth Type" />}
                  fullWidth
                  size="small"
                  value={boothType?boothType:''}
                  onChange={(e)=>{setBoothType( e.target.value); console.log(e.target.value)}}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='Island'>Island</MenuItem>
                  <MenuItem value='End cap'>End cap</MenuItem>
                  <MenuItem value='Inline'>Inline</MenuItem>
                  <MenuItem value='Corner'>Corner</MenuItem>
                  <MenuItem value='Peninsula'>Peninsula</MenuItem>
                </Select>
              </FormControl>              
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="Public Booth Notes"
                margin="dense"
                label="Public Booth Notes"
                type="name"
                fullWidth
                variant="outlined"
                size="small"
                value={publicNote}
                onChange={(e)=>{setPublicNote(e.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="Private Booth Notes"
                margin="dense"
                label="Private Booth Notes"
                type="name"
                fullWidth
                variant="outlined"
                size="small"
                value={privateNote}
                onChange={(e)=>{setPrivateNote(e.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={6}>
              <Box mt={2}>
                <FormControlLabel
                  value="start"
                  control={<Switch color="primary" checked={holdBooth} 
                  onChange={(event)=>{
                    setHoldBooth(event.target.checked)
                  }}/>}
                  label="Hold Booth"
                  labelPlacement="start"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSet}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
    
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setLoading:  data=>dispatch(setLoading(data)),
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SetBoothModal);