import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];



function UnGrouppingModal(props) {
  const { onClose, onOk, open, ungroupCnt, setUngrouppingBooth } = props;

  const onChangeName = (e, i)=>{
    var ungrouppingBooth = {...props.ungrouppingBooth}
    ungrouppingBooth[i] = e.target.value
    setUngrouppingBooth(ungrouppingBooth);
  }
  useEffect(() => {
    setUngrouppingBooth({});
  }, [])

  const renderSetGroup=()=>{
    var elements = [];
    var indexing = [];
    for(var i = 0; i < ungroupCnt; i++){
      indexing.push(i);
    }
    if(indexing.length > 0){
      elements = 
      indexing.map((item, index)=>{
        return(
          <TextField
            key={index}
            margin="dense"
            label="Booth Number"
            type="name"
            fullWidth
            variant="standard"
            onChange={(e)=>onChangeName(e, item)}
          />
        )
      })
    }
    return elements;
  }

  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'ungroupping-dialog-title'}}
      aria-labelledby="ungroupping-dialog-title"
      open={open}>
      <DialogTitle id='ungroupping-dialog-title'>UnGroupping</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        {renderSetGroup()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(UnGrouppingModal);