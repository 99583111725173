import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setPlanner } from '../../actions/main'
import { setOpenLogin,setOpenSignup,setSnackMsg, setOpenSnack, setOpenError } from '../../actions/ui'
import { NavLink } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import { v4 as uuidv4 } from 'uuid';
import { parseTokenValue, getUrlToken } from '../../utils/Common'
import { getTokenCookie, setTokenPlanner, safeTrim } from '../../utils/Common'
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import InputBase from '@mui/material/InputBase';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { uuid4 } from './sketch_right/utils';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import BusinessIcon from '@mui/icons-material/Business';
import Grid from '@mui/material/Grid';
import ScaleLoader from 'react-spinners/ScaleLoader'

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
var timeout = false;
var g_searchVal = ""
  var map_companies = [];
  var magicVerficationTimer = false
  var magicVerifyInfo = false
  var verifyCnt = 0;
function Index(props) {
  const { onClose, onOk, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [formValue, setFormValue] = React.useState({});
  const [searchVal, setSearchVal] = React.useState("");
  const [step, setStep] = React.useState(1);
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  const [searchedList, setSearchedList] = React.useState([]);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [company, setCompany] = React.useState(null);
  const [orgCompany, setOrgCompany] = React.useState(null);
  const [startVerification, setStartVerification] = React.useState(false);
  const [searchZip, setSearchZip] = React.useState(false);
  let query = useQuery();

  useEffect(() => {
    setStep(1)
    setSearchVal("")
    setSearchedList([])
    setOpenDropdown(false)
    setCompany(false)
    setOrgCompany(false)
    setSearchZip(false)
    stopMagicVerfication()
  }, [props.ui.openSignup])

  useEffect(() => {
    g_searchVal = searchVal
  }, [searchVal])
  
  const onChangeValue = (e)=>{
    setFormValue({...formValue, [e.target.name]: e.target.value});
  }

  const startMagicVerfication = ()=>{
    setStep(4)
    setStartVerification(true)
    magicVerficationTimer = setInterval(()=>{
      checkMagicVerfication()
    }, 4000);
  }
  const stopMagicVerfication = ()=>{
    setStartVerification(false)
    if(magicVerficationTimer){
      clearInterval(magicVerficationTimer)
      magicVerficationTimer = false;
    }
  }
  const checkMagicVerfication=()=>{
    verifyCnt++;
    if(verifyCnt == 225){ //15min * 60/4
      stopMagicVerfication()
      props.setOpenSnack(true);
      props.setSnackMsg("Failed to sign up");
    }
    var postData = 
    {
      COMPANY_NAME: formValue.COMPANY_NAME?formValue.COMPANY_NAME:'',
      CITY: formValue.CITY?formValue.CITY:'',
      COUNTRY: formValue.COUNTRY?formValue.COUNTRY:'',
      FIRST_NAME: formValue.first_name?formValue.first_name:'',
      LAST_NAME: formValue.last_name?formValue.last_name:'',
      TITLE: formValue.title?formValue.title:'',
      EMAIL: formValue.EMAIL?formValue.EMAIL:'',
      ZIP_CODE: formValue.ZIP_CODE?formValue.ZIP_CODE:'',
      PASSWORD: formValue.PASSWORD?formValue.PASSWORD:'',
      PHONE: formValue.PHONE?formValue.PHONE:'',
      STATE: formValue.STATE?formValue.STATE:'',
      WEBSITE: formValue.STATE?formValue.WEBSITE:'',
      KEY_ID: uuidv4(),
      ADDRESS1: formValue.ADDRESS1?formValue.ADDRESS1:'',
      ADDRESS2: formValue.ADDRESS2?formValue.ADDRESS2:'',
      BOOKMARKS: []
    }
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner&token=${getTokenCookie()}&access_type=verify&key_id=${magicVerifyInfo.KEY_ID}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          stopMagicVerfication()
          var tokenInfo = await parseTokenValue(eshowResponse.TOKEN)
          setTokenPlanner(eshowResponse.TOKEN, tokenInfo)
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Successfully created new planner");
          props.setOpenSignup(false)
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          var show_key = urlParams.get('show_key')  
          var urlToken = getUrlToken()
          props.setPlanner({tokenInfo, key:urlToken})
          
        }
        else{
          if(eshowResponse.MESSAGE != 'Pending'){
            stopMagicVerfication()
            // props.setOpenSnack(true);
            // props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login");
            props.setOpenSignup(false)
            props.setOpenError(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to sign up")
          }
        }
        // setProcessing1(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const handleSignup = (e)=>{
    e.preventDefault();    
    if(!formValue.COMPANY_NAME){
      props.setOpenSnack(true);
      props.setSnackMsg("Company is required");
      return false;                      
    }
    if(!formValue.PASSWORD || formValue.PASSWORD != formValue.confirm_password){
      props.setOpenSnack(true);
      props.setSnackMsg("Invalid password");
      return false;
    }
    var postData = 
    {
      COMPANY_NAME: formValue.COMPANY_NAME?formValue.COMPANY_NAME:'',
      CITY: formValue.CITY?formValue.CITY:'',
      COUNTRY: formValue.COUNTRY?formValue.COUNTRY:'',
      first_name: formValue.first_name?formValue.first_name:'',
      last_name: formValue.last_name?formValue.last_name:'',
      TITLE: formValue.title?formValue.title:'',
      EMAIL: formValue.EMAIL?formValue.EMAIL:'',
      ZIP_CODE: formValue.ZIP_CODE?formValue.ZIP_CODE:'',
      PASSWORD: formValue.PASSWORD?formValue.PASSWORD:'',
      PHONE: formValue.PHONE?formValue.PHONE:'',
      STATE: formValue.STATE?formValue.STATE:'',
      WEBSITE: formValue.STATE?formValue.WEBSITE:'',
      KEY_ID: uuidv4(),
      ADDRESS1: formValue.ADDRESS1?formValue.ADDRESS1:'',
      ADDRESS2: formValue.ADDRESS2?formValue.ADDRESS2:'',
      BOOKMARKS: []
    }
    var formdata = {
      query:`${ESHOW_URL()}?method=putPlanner&token=${getTokenCookie()}`,
      postData: postData,
      returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner&token=${getTokenCookie()}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
      const eshowResponse = res.data;
      if(0){
        if(eshowResponse.SUCCESS == true){
            
          var tokenInfo = await parseTokenValue(eshowResponse.TOKEN)
          setTokenPlanner(eshowResponse.TOKEN, tokenInfo)
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Successfully created new planner");
          props.setOpenSignup(false)
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          var show_key = urlParams.get('show_key')  
          var urlToken = getUrlToken()
          props.setPlanner({tokenInfo, key:urlToken})
        }
        else{
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to sign up");
        }
      }
      if(eshowResponse.SUCCESS == true){
        magicVerifyInfo = (eshowResponse)
        startMagicVerfication()
      }
      else{
        props.setOpenSignup(false)
        props.setOpenError(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to sign up")
      }
    }).catch(error => {
        console.log("error", error);
    })
  }

  const doSearch = (name)=>{    

    if(name && name != ''){
      if(timeout)
        clearTimeout(timeout);
      setLoadingFlag(true)
      timeout = setTimeout(async ()=> {        
        var url = `${ESHOW_URL()}?method=getCompany&token=${getTokenCookie()}&company_name=${name}`
        if(searchZip){
          url = `${url}&zip_code =${searchZip}`
        }
        axiosInstance.get(url).then(res=>{
          setLoadingFlag(false)
          if(g_searchVal && g_searchVal!=''){
            if(res.data){
              console.log(res.data)
              var result = res.data
              if(result)
                setSearchedList(result)
              else{
                setSearchedList([])
              }
            }
          }
          else{
            setSearchedList([])
            setOpenDropdown(false)
          }
          
        }).catch(error => {
            console.log("error", error);
        })
        setOpenDropdown(true)
      }, 300);
    }
    else{
      setSearchedList([])
      setOpenDropdown(false)
    }
  }

  return (
    <Dialog
      className='top-modal'
      fullWidth={true}
      maxWidth={"sm"} open={props.ui.openSignup}>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        

        <Box style={{}}>
          <IconButton style={{float:'right', padding:5}} color="inherit" onClick={()=>{props.setOpenSignup(false)}} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom style={{textAlign: 'center', fontSize: '25px',color: '#1565c0'}}>
            {startVerification?'Verify Login':'Sign up'}
          </Typography>
        </Box>
        {
          step == 1 &&
          <Box>
            <form>
              <TextField
                required
                name="EMAIL"
                margin="dense"
                label="Email"
                type="text"
                fullWidth
                variant="standard"
                onChange={onChangeValue}
              />
              <TextField
                required
                name="first_name"
                margin="dense"
                label="First Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={onChangeValue}
              />
              <TextField
                required
                name="last_name"
                margin="dense"
                label="Last Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={onChangeValue}
              />
              <TextField
                name="title"
                margin="dense"
                label="Title"
                type="text"
                fullWidth
                variant="standard"
                onChange={onChangeValue}
              />
              <TextField
                required
                name="PASSWORD"
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={onChangeValue}
              />
              <TextField
                required
                name="confirm_password"
                margin="dense"
                label="Confirm Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={onChangeValue}
              />
              <Box mt={2} textAlign='center'>
                <Button variant="contained" color="primary" size="large" type="button" 
                  onClick={()=>{
                    if(!formValue.EMAIL){
                      props.setOpenSnack(true);
                      props.setSnackMsg("Email is required");
                      return false;                      
                    }
                    if(!formValue.first_name){
                      props.setOpenSnack(true);
                      props.setSnackMsg("First Name is required");
                      return false;
                    }
                    if(!formValue.last_name){
                      props.setOpenSnack(true);
                      props.setSnackMsg("Last Name is required");
                      return false;
                    }
                    if(!formValue.PASSWORD || formValue.PASSWORD != formValue.confirm_password){
                      props.setOpenSnack(true);
                      props.setSnackMsg("Invalid password");
                      return false;
                    }

                    setStep(2)
                  }}
                >
                  Next
                </Button>
              </Box>
            </form>
          </Box>
        }
        {
          step==2 &&
          <Box>
            <Box mb={1}>
              <TextField
                required
                name="ZIP"
                margin="dense"
                label="Zip"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e)=>{setSearchZip(e.target.value)}}
              />
            </Box>
            <Box style={{position:'relative'}} mb={3}>
              <Box style={{border:'1px solid #e0e0e0', borderRadius:'4px',display:'flex'}}>
                <InputBase
                    sx={{ ml: 1, flex: 1, width:'calc(100% - 50px)' }}
                    placeholder="Search company"
                    inputProps={{ 'aria-label': 'search booth' }}
                    value={searchVal}
                    onChange={
                      (e)=>{
                        // if(e.target.value == "" || !e.target.value)
                        {
                          setSearchVal(e.target.value)
                          doSearch(e.target.value)
                        }
                      }
                    }
                />
                <IconButton size="small" type="button" onClick = {()=>doSearch(searchVal)} aria-label="search">
                    <SearchIcon />
                </IconButton>
              </Box>
              <Box mt={3} sx={{height:'450px', overflowY:'auto'}}>
                <Grid container spacing={2}>
                  <Grid item xs={4}
                      onClick={
                        ()=>{
                          var tmp = {KEY_ID:uuid4(),new:true}
                          setCompany({...tmp})                          
                          setOrgCompany({...tmp})
                          setStep(step+1)
                        }
                      }
                    > 
                      <Box className={`company-tile ${company?.new?"selected":""}`} p={2}>
                        <label style={{wordBreak:'break-all', cursor: 'pointer'}}>New Company</label>
                        <Box sx={{textAlign:'center'}}>
                          <AddCircleOutlineIcon sx={{marginTop: '3px'}} fontSize="small"/>
                        </Box>                        
                      </Box>
                  </Grid>
                  {searchedList.map((row, index) => (
                    <Grid item xs={4}
                      key={index}
                      onClick={()=>{
                        var tmp = {
                          'KEY_ID' : row['KEY_ID'],
                          'COMPANY_NAME': row['COMPANY_NAME'],
                          'ADDRESS1':row['ADDRESS1'],
                          'ADDRESS2':row['ADDRESS2'],
                          'CITY':row['CITY'],
                          'STATE':row['STATE'],
                          'ZIP_CODE':row['ZIP_CODE'],
                          'COUNTRY':row['COUNTRY'],
                          'PHONE':row['PHONE'],
                          'WEBSITE':row['WEBSITE']
                        }
                        setCompany({...tmp});
                        setOrgCompany({...tmp});
                        setSearchVal(row['COMPANY_NAME']?safeTrim(row['COMPANY_NAME']):""); 
                        setFormValue({...formValue, ...tmp})
                        setStep(step+1)
                      }} 
                      sx={{paddingTop: '4px', paddingBottom:'4px', cursor:'pointer'}}
                    > 
                      <Box className={`company-tile ${company?.KEY_ID == row['KEY_ID']?"selected":""}`} p={2} style={{background:((map_companies.includes(row['KEY_ID']) || row['EXHIBITOR'])?'#65e865':'inherit')}}>
                        <label style={{wordBreak:'break-all'}}>{row['COMPANY_NAME']}</label><br/>
                        {row['BOOTH_NO'] &&
                        <>
                        <label style={{wordBreak:'break-all', fontSize:13.5}}>Booth: {row['BOOTH_NO']}</label><br/>
                        </>
                        }
                        <BusinessIcon sx={{marginTop: '3px'}} fontSize="small"/>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <Box mt={2} textAlign='center'>
              <Button variant="contained" color="primary" size="large" type="button" onClick={()=>{setStep(3)}}>
                Next
              </Button>
            </Box>
          </Box>
        }
        {
          step==3 &&
            <form>      
              <TextField
                name="COMPANY_NAME"
                margin="dense"
                label="COMPANY *"
                type="text"
                fullWidth
                variant="standard"
                value={formValue.COMPANY_NAME??''}
                onChange={onChangeValue}
              />          
              <TextField
                name="ADDRESS1"
                margin="dense"
                label="ADDRESS1"
                type="text"
                fullWidth
                variant="standard"
                value={formValue.ADDRESS1??''}
                onChange={onChangeValue}
              />
              <TextField
                name="ADDRESS2"
                margin="dense"
                label="ADDRESS2"
                type="text"
                fullWidth
                variant="standard"
                value={formValue.ADDRESS2??''}
                onChange={onChangeValue}
              />
              <TextField
                name="STATE"
                margin="dense"
                label="STATE"
                type="text"
                fullWidth
                variant="standard"
                value={formValue.STATE??''}
                onChange={onChangeValue}
              />
              <TextField
                name="CITY"
                margin="dense"
                label="CITY"
                type="text"
                fullWidth
                variant="standard"
                value={formValue.CITY??''}
                onChange={onChangeValue}
              />
              <TextField
                name="ZIP_CODE"
                margin="dense"
                label="ZIP_CODE"
                type="text"
                fullWidth
                variant="standard"
                value={formValue.ZIP_CODE??''}
                onChange={onChangeValue}
              />
              <TextField
                name="COUNTRY"
                margin="dense"
                label="COUNTRY"
                type="text"
                fullWidth
                variant="standard"
                value={formValue.COUNTRY??''}
                onChange={onChangeValue}
              />
              <TextField
                name="WEBSITE"
                margin="dense"
                label="WEBSITE"
                type="text"
                fullWidth
                variant="standard"
                value={formValue.WEBSITE??''}
                onChange={onChangeValue}
              />
              <Box mt={2} textAlign='center'>
                <Button variant="contained" color="primary" size="large" type="button" 
                  onClick={(e)=>{
                    handleSignup(e)
                  }}
                >
                  Sign up
                </Button>
              </Box>
            </form>
        }
        {
          startVerification && 
          <Box>
            <Box mt={2}>
              <div dangerouslySetInnerHTML={{ __html: magicVerifyInfo.MESSAGE??'' }}></div>
            </Box>
            <Box mt={2} textAlign={'center'}>
            <ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2} margin={2} height={28} width={4}/>
            </Box>
          </Box>
        }
      </DialogContent>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) ,
        setOpenLogin: data=>dispatch(setOpenLogin(data)) ,
        setOpenSignup: data=>dispatch(setOpenSignup(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),
        setPlanner: data=>dispatch(setPlanner(data)) ,
        setOpenError: data=>dispatch(setOpenError(data)) ,
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);