import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import {PaperComponent} from '../../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function Index(props) {
  const { onClose,  open } = props;
  var mainLayers = props.main.mainLayers;
  const [boothType, setBoothType] = useState('');

  useEffect(() => {
    var target = props.main.menuControl.target;
    if(target && target.get('boothType')){
      setBoothType(target.get('boothType'))
    }
    else{      
      setBoothType('')
    }
  }, [open])
  
  const onChange = (e)=>{
    setBoothType(e.target.value)
  }

  const onOk = (e)=>{
    if(boothType)
      props.onOk(boothType)
  }
  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'boothtype-dialog-title'}}
      aria-labelledby="boothtype-dialog-title" 
      open={open}>
      <DialogTitle id='boothtype-dialog-title' style={{display:'flex', alignItems:'center'}}>Booth Type</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
          <FormControl fullWidth  sx={{ mt: 1, minWidth: 150 }}>
            <InputLabel id="demo-controlled-open-select-label">Booth Type</InputLabel>
            <Select
              value={boothType}
              label="Booth Type"
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value='Island'>Island</MenuItem>
              <MenuItem value='End cap'>End cap</MenuItem>
              <MenuItem value='Inline'>Inline</MenuItem>
              <MenuItem value='Corner'>Corner</MenuItem>
              <MenuItem value='Peninsula'>Peninsula</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);