import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setShowGrid, setBoothNumberLayer, setBoothOutlineLayer, setAnnotationLayer, setSponsorshipsLayer, setVenueLayer, setOriginalLayer, setDeletedLayer } from '../../actions/main'
import LayersIcon from '@mui/icons-material/Layers';
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid} from '../../utils/CanvasTool'
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function LayerModal(props) {
  const { onClose, selectedValue, open } = props;
  var mainLayers = props.main.mainLayers;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    // onClose(value);
    var tmp = [...mainLayers];
    for(var i = 0; i <tmp.length; i++){
        if(tmp[i]['id'] == value){
            tmp[i] = {...tmp[i], outline_selected:!(tmp[i]['outline_selected']||tmp[i]['number_selected']), number_selected:false};
        }
    }
    props.setMainLayers(tmp)

  };

  useEffect(() => {
    if(open){
      var mainCanvas = props.main.mainCanvas      
      props.setShowGrid(mainCanvas.get('showGrid'))
    }
  }, [open])

  

  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'layer-dialog-title'}}
      aria-labelledby="layer-dialog-title"
      open={open}>
    <DialogTitle id='layer-dialog-title' style={{display:'flex', alignItems:'center'}}><LayersIcon style={{marginRight: 10}}/> Layer Selection</DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <List sx={{ pt: 0 }}>
          {/* {mainLayers.map((layer) => (
            <ListItem button onClick={() => handleListItemClick(layer['id'])} key={layer['id']}>
              <ListItemAvatar>
                  {(layer['outline_selected']||layer['number_selected'])?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
              </ListItemAvatar>
              <ListItemText primary={layer['name']} />
            </ListItem>
          ))} */}
          
          <ListItem button onClick={() => props.setBoothOutlineLayer(!props.main.layerSelect.booth_outline)}>
            <ListItemAvatar>
                {(props.main.layerSelect.booth_outline)?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={'Booth Outline'} />
          </ListItem>
          
          <ListItem button onClick={() => props.setBoothNumberLayer(!props.main.layerSelect.booth_number)}>
            <ListItemAvatar>
                {(props.main.layerSelect.booth_number)?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={'Booth Number'} />
          </ListItem>
          
          <ListItem button onClick={() => props.setAnnotationLayer(!props.main.layerSelect.annotation)}>
            <ListItemAvatar>
                {(props.main.layerSelect.annotation)?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={'Annotation'} />
          </ListItem>

          <ListItem button onClick={() => props.setSponsorshipsLayer(!props.main.layerSelect.sponsorships)}>
            <ListItemAvatar>
                {(props.main.layerSelect.sponsorships)?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={'Sponsorships'} />
          </ListItem>
          
          <ListItem button onClick={() => props.setVenueLayer(!props.main.layerSelect.venue)}>
            <ListItemAvatar>
                {(props.main.layerSelect.venue)?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={'Venue Columns'} />
          </ListItem>
          
          <ListItem button onClick={() => props.setOriginalLayer(!props.main.layerSelect.original)}>
            <ListItemAvatar>
                {(props.main.layerSelect.original)?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={'Original Booth Outlines'} />
          </ListItem>
          <ListItem button onClick={() => props.setDeletedLayer(!props.main.layerSelect.deleted)}>
            <ListItemAvatar>
                {(props.main.layerSelect.deleted)?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={'Deleted Booths'} />
          </ListItem>
          <ListItem button onClick={() => {
            props.setShowGrid(!props.main.showGrid)
            var mainCanvas = props.main.mainCanvas
            mainCanvas.set({showGrid: !mainCanvas.showGrid})
            mainCanvas.renderAll()
            if(mainCanvas.showGrid &&mainCanvas.gridSize){
              redrawGrid(props.main.g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
            }
            else{
              removeGrid(props.main.mainCanvas)
            }
          }}>
            <ListItemAvatar>
                {(props.main.showGrid)?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={'Grid'} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) ,
        setShowGrid: value=>dispatch(setShowGrid(value)) ,
        setBoothNumberLayer: value=>dispatch(setBoothNumberLayer(value)) ,
        setBoothOutlineLayer: value=>dispatch(setBoothOutlineLayer(value)) ,
        setAnnotationLayer: value=>dispatch(setAnnotationLayer(value)) ,
        setSponsorshipsLayer: value=>dispatch(setSponsorshipsLayer(value)) ,
        setVenueLayer: value=>dispatch(setVenueLayer(value)) ,
        setOriginalLayer: value=>dispatch(setOriginalLayer(value)) ,
        setDeletedLayer: value=>dispatch(setDeletedLayer(value)) ,
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(LayerModal);