import * as React from 'react';
import { fabric } from "fabric";
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit,  setLoading } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import rgbHex from "rgb-hex";
import axios from 'axios';
import qs, { parse } from 'qs'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, setObjectBG, resizeBooth, addText, px2unit, unit2Px} from '../../utils/CanvasTool'

import { getDefaultGroup } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";

import { parseToken,convertRgbHex } from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SetBoothGroupModal(props) {
  
  let query = useQuery();
  const [snackMsg, setSnackMsg] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [activeObject, setActiveObject] = React.useState(null);
  const [priceCheck , setPriceCheck] = React.useState([]);
  const [premiumCheck , setPremiumCheck] = React.useState('');
  const [discountCheck, setDiscountCheck] = React.useState('');
  const [boothName , setBoothName] = React.useState('');
  const [boothWidth , setBoothWidth] = React.useState(0);
  const [boothHeight , setBoothHeight] = React.useState(0);
  const [boothLeft , setBoothLeft] = React.useState(0);
  const [boothTop , setBoothTop] = React.useState(0);
  const [boothLeftOffset , setBoothLeftOffset] = React.useState(0);
  const [boothAngle, setBoothAngle]  = React.useState(0);
  const [boothTopOffset , setBoothTopOffset] = React.useState(0);
  const [boothGroup , setBoothGroup] = React.useState('');
  const [labelPosition , setLabelPosition] = React.useState('bottom-left');
  

  
  var mainCanvas = props.main.mainCanvas
  const { onClose, onOk , open } = props;
  var mainLayers = props.main.mainLayers;
  
  var pricingOptions = props.main.pricingOptions
  var premiumOptions = props.main.premiumOptions
  var discountOptions = props.main.discountOptions
  var groupOptions = props.main.groupOptions
  
  var activeFile;
  var boothFileList = props.main.boothFileList;
  for(var i = 0; i <boothFileList.length; i++){
      if(boothFileList[i]['active']){
          activeFile = boothFileList[i];
          break;
      }
  }

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }

  const onSet = ()=>{
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
    }
    if(activeObject && activeObject.type=="activeSelection"){
      var objects = activeObject.getObjects()
      for(var i = 0; i < objects.length; i++ ){
        if(objects[i].class!="booth") continue;
        objects[i].set({
          boothGroup: boothGroup,
          modified: true
        })
        var premiumCheck = objects[i].get('premiumCheck')
        var priceCheck = objects[i].get('priceCheck')
        var discountCheck = objects[i].get('discountCheck')
        var exhibitors = objects[i].exhibitors
        var bgColor = "#dadada"
        {
          for(var k = 0; k < groupOptions.length; k++){
              if(groupOptions[k].KEY_ID == boothGroup){
                  var groupOption = groupOptions[k]
                  
                  if(objects[i].holdBooth == '1'){
                    bgColor = convertRgbHex(groupOption['RESERVED']);
                  }
                  else if(objects[i].sold == '1'){
                    bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                  }
                  else if((priceCheck && priceCheck.length > 0) || (premiumCheck && premiumCheck.length > 0) || (discountCheck && discountCheck.length > 0)){
                      bgColor = convertRgbHex(groupOption['AVAILABLE']);
                  }
              }
          }
        }

        objects[i].set({        
          deselectedColor: bgColor
        })
        setObjectBG(mainCanvas, objects[i], bgColor)

      }
    }
    else if(activeObject && activeObject.class=="booth"){
      var objects = mainCanvas.getObjects();
      
      activeObject.set({
        boothGroup: boothGroup,
        modified: true
      })
      mainCanvas.renderAll()
      var resizedObject = activeObject
      
      var premiumCheck = activeObject.get('premiumCheck')
      var priceCheck = activeObject.get('priceCheck')
      var discountCheck = activeObject.get('discountCheck')
      var exhibitors = activeObject.exhibitors
      // var boothGroup = activeObject.boothGroup;
      var bgColor = "#dadada"
      {
        for(var k = 0; k < groupOptions.length; k++){
            if(groupOptions[k].KEY_ID == boothGroup){
                var groupOption = groupOptions[k]
                if(resizedObject.holdBooth == '1'){
                  bgColor = convertRgbHex(groupOption['RESERVED']);
                }
                else if(resizedObject.sold == '1'){
                  bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                }
                else if((priceCheck && priceCheck.length > 0) || (premiumCheck && premiumCheck.length > 0) || (discountCheck && discountCheck.length > 0)){
                    bgColor = convertRgbHex(groupOption['AVAILABLE']);
                }
            }
        }
      }

      resizedObject.set({        
        deselectedColor: bgColor
      })
      setObjectBG(mainCanvas, resizedObject, bgColor)
      
    }
    mainCanvas.renderAll()
    onOk(activeObject);
  }
    
  useEffect(() => {
    var mainCanvas = props.main.mainCanvas
    if(open && mainCanvas){      
      var activeObject = mainCanvas.getActiveObject();

      if(activeObject.type == "group" && (activeObject.class=="booth" || activeObject.class=="booth-group")){
       
        setBoothGroup(activeObject.boothGroup?activeObject.boothGroup:"")
        
      }
      else if(activeObject.type == "activeSelection"){
        var objects = activeObject.getObjects()
        var arr1 = [];
        for(var i = 0; i < objects.length; i++ ){
          if(objects[i].boothGroup){
            arr1.push(objects[i].boothGroup)
          }
        }
        var mf = 1;
        var m = 0;
        var maxGroup
        for (var i=0; i<arr1.length; i++)
        {
                for (var j=i; j<arr1.length; j++)
                {
                        if (arr1[i] == arr1[j])
                        m++;
                        if (mf<m)
                        {
                          mf=m; 
                          maxGroup = arr1[i];
                        }
                }
                m=0;
        }
        setBoothGroup(maxGroup?maxGroup:null)
      }
      else{        
        setBoothGroup(null)
      }

      setActiveObject(activeObject)
    }
  }  , [open])
  
  const onChangeName = (e)=>{
    setBoothName(e.target.value)
  }
  console.log("boothGroup", boothGroup)
  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'setgroup-dialog-title'}}
      aria-labelledby="setgroup-dialog-title" 
      fullWidth={true}
      maxWidth={"sm"} 
      open={open}
    >
      <DialogTitle id='setgroup-dialog-title'>Booth Group</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container spacing={2} style={{marginTop:15}}>
            <Grid item xs={12}>
              <FormControl variant="outlined"
                size="small" fullWidth style={{marginTop:'7px'}}>
                <InputLabel shrink={true}>Group</InputLabel>
                <Select
                  input={<OutlinedInput notched label="Group" />}
                  fullWidth
                  size="small"
                  value={boothGroup?boothGroup:''}
                  onChange={(e)=>{setBoothGroup( e.target.value); console.log(e.target.value)}}
                  displayEmpty
                >
                  <MenuItem  value="">&nbsp;</MenuItem>
                  {
                    groupOptions.map((row, index)=>{
                      return(
                        <MenuItem key={index} value={row.KEY_ID?row.KEY_ID.trim():""}>{row.TITLE}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>              
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSet}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={snackMsg}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      />
    </Dialog>
    
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setLoading:  data=>dispatch(setLoading(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SetBoothGroupModal);