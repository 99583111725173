import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import {  px2unit, unit2Px } from '../../utils/CanvasTool'
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function Index(props) {
  const [transferBooth, setTransferBooth] = useState(false) 
  const [holdBooth, setHoldBooth] = React.useState(false);  
  const { onClose, onOk, onChange, open } = props;
  var mainLayers = props.main.mainLayers;

  useEffect(() => {
  }, [])
  
  const onChangeName = (e)=>{
    onChange(e)
  }

  const getBoothList=()=>{
    var boothlist = [];
    var target_width = 0;
    var target_height = 0;
    var target=props.main.menuControl?.target
    var booth_number = ''
    var convertRateX= 1;
    if(props.main.mainCanvas){
      convertRateX = Math.abs(props.main.mainCanvas.convertRateX)
    }
    if(target){
      target_width = px2unit(target.width,convertRateX,props.main.mainCanvas.mapUnit).toFixed(1)
      target_height = px2unit(target.height,convertRateX,props.main.mainCanvas.mapUnit).toFixed(1)
    }
    if(props.main.mainCanvas){
      var objects = props.main.mainCanvas.getObjects();
      for(var i = 0; i< objects.length; i++){
        var object = objects[i]
        if(object.get('class')=="booth" && !object.sold){
          var booth_number = object.booth_number          
          var transfer_width = px2unit(object.width,convertRateX,props.main.mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
          var transfer_height = px2unit(object.height,convertRateX,props.main.mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
          if(booth_number){
            boothlist.push({booth_number, label:`${booth_number} (${transfer_width}x${transfer_height})`})
          }
        }
      }
    }
    boothlist.sort(function (a, b) {
      return (a.booth_number*1 - b.booth_number*1);
    });
    return boothlist;
  }
  var booth_list = getBoothList();
  var target=props.main.menuControl?.target
  var booth_number = ''
  if(target){
    booth_number = target.booth_number
  }
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'transfer-dialog-title'}}
      aria-labelledby="transfer-dialog-title"
      fullWidth={true}
      maxWidth={"xs"} open={open}>
      <DialogTitle id='transfer-dialog-title' style={{display:'flex', alignItems:'center'}}>Transfer Booth {booth_number}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined"
              size="small" fullWidth style={{marginTop:'30px'}}>
              <InputLabel shrink={true}>To Booth:</InputLabel>
              <Select
                input={<OutlinedInput notched label="Transfer Booth" />}
                value={transferBooth}
                onChange={(e)=>setTransferBooth(e.target.value)}
              >
                {
                  booth_list.map((booth, index)=>{
                    return(
                      <MenuItem key={index} value={booth.booth_number}>{booth.label}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <FormControlLabel
                value="start"
                control={<Switch color="primary" checked={holdBooth} 
                onChange={(event)=>{
                  setHoldBooth(event.target.checked)
                }}/>}
                label="Hold Booth"
                labelPlacement="start"
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e)=>onOk(transferBooth, holdBooth)}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);