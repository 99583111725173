import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'

import { fabric } from "fabric";
import { SVG} from '@svgdotjs/svg.js'
import {PaperComponent} from '../../utils/Common'




function UnGrouppingModal(props) {
  
  const { onClose, onOk, open, ungroupCnt, setUngrouppingBooth } = props;
  var mainCanvas = props.main.mainCanvas;

  const onChangeName = (e, i)=>{
    var ungrouppingBooth = {...props.ungrouppingBooth}
    ungrouppingBooth[i] = e.target.value
    setUngrouppingBooth(ungrouppingBooth);
  }

  const unGroupItems = ()=>{
    if (mainCanvas && !mainCanvas.getActiveObject()) {
        return;
    }
    if (mainCanvas.getActiveObject().type !== 'group' || (mainCanvas.getActiveObject().get('class') !== 'booth-group' && mainCanvas.getActiveObject().get('class') !== 'booth')) {
        return;
    }
    
    var activeObject = mainCanvas.getActiveObject();
    var ungroupedItems = [];
    if (activeObject.child_booths) {
        var objects = activeObject.child_booths;
        if(objects.length > 0){
            if(objects[0]._cacheCanvas == undefined && objects[0] !== null)
            {
                fabric.util.enlivenObjects(objects, function(objects) {
                    // var origRenderOnAddRemove = mainCanvas.renderOnAddRemove;
                    // mainCanvas.renderOnAddRemove = false;
                  
                    objects.forEach(function(o) {
                      ungroupedItems.push(o)
                    });
                });
            }
            else{
                for(var i = 0; i < objects.length; i++){
                    var objectA  = objects[i];                    
                    ungroupedItems.push(objectA)
                }
            }
        }
    }
    else if (activeObject.get('class') == 'booth') 
    {      
        return; 
    }
    console.log("ungroupedItems ", ungroupedItems)
    var sel = new fabric.ActiveSelection(ungroupedItems, {
      canvas: mainCanvas,
    });
    var drawermain = SVG();
    sel.left = 0;
    sel.top = 0;
    var rateX = 300/sel.width;
    var rateY = 200/sel.height;

    sel.scaleX = Math.min(rateX, rateY)
    sel.scaleY = Math.min(rateX, rateY)
    sel.left = 300/2 - sel.width*sel.scaleX/2

    drawermain.svg(sel.toSVG())
    console.log()

    setTimeout(function(){
      drawermain.addTo('#previewSvg').size('100%', '100%')
      
    },100)

}

  useEffect(() => {
    unGroupItems({});
  }, [])

  const renderSetGroup=()=>{
    var elements = [];
    var indexing = [];
    for(var i = 0; i < ungroupCnt; i++){
      indexing.push(i);
    }
    if(indexing.length > 0){
      elements = 
      indexing.map((item, index)=>{
        return(
          <TextField
            key={index}
            margin="dense"
            label="Booth Number"
            type="name"
            fullWidth
            variant="standard"
            onChange={(e)=>onChangeName(e, item)}
          />
        )
      })
    }
    return elements;
  }

  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'ungroupping-dialog-title'}}
      aria-labelledby="ungroupping-dialog-title"
      open={open}>
      <DialogTitle id='ungroupping-dialog-title'>Uncombine</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        {/* {renderSetGroup()} */}
        <div id="previewSvg" style={{marginTop: 20, width:'100%', height:'200px'}}>
          
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(UnGrouppingModal);