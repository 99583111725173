import * as React from 'react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileUpload from '@mui/icons-material/CloudUpload';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import LayersIcon from '@mui/icons-material/Layers';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import { Canvg } from 'canvg';
import { setLoading,setBoothFileList } from '../../actions/main.js'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setBoothHistory } from '../../actions/booth_history.js'
import { fabric } from "fabric";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { v4 as uuidv4 } from 'uuid';
import {  px2unit, unit2Px } from '../../utils/CanvasTool.js'
import IconButton from '@mui/material/IconButton';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {PaperComponent, getTokenCookie} from '../../utils/Common.js'

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls, MapControls } from '../../utils/OrbitControls'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <CloudUploadOutlinedIcon />,
    2: <LayersIcon />,
    3: <InsertPhotoOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};
const steps = ['Select Background Source', 'Select Layer', 'Final image'];
var moveTimer = -1
var step2Canvas
var g_offsetX_Ft
var g_offsetY_Ft
var g_isDragging = false
let g_mapWidth = 860
let g_mapHeight = 500
var scene, camera, content, boothModel, font, meshes, g_initDistance
let renderer;
let controls;
let animateId;
let modelUrl;
let ground;

function OverviewModal(props) {
  const mainCanvasRef = useRef(null);
  const { onClose, onOk, onChange, open } = props;
  const [step, setStep] = React.useState(1);
  const [filetype, setFiletype] = React.useState("dwg");
  const [imgUrl, setImageUrl] = React.useState(null);
  const [dwgSvg, setDwgSvg] = React.useState(null);
  const [dwgFilteredSvg, setDwgFilteredSvg] = React.useState(null);
  const [mainLayers, setMainLayers] = React.useState([])
  const [imageWidth, setImageWidth] = React.useState(0)
  const [imageHeight, setImageHeight] = React.useState(0)
  const [imageWidth_Ft, setImageWidth_Ft] = React.useState(0)
  const [imageHeight_Ft, setImageHeight_Ft] = React.useState(0)
  const [factor, setFactor] = React.useState(1)
  const [prepareDwgimg, setPrepareDwgimg] = React.useState(false)
  const [preparePreviewDwgimg, setPreparePreviewDwgimg] = React.useState(false)  
  const [offsetX_Ft, setOffsetX_Ft] = React.useState(0)
  const [offsetY_Ft, setOffsetY_Ft] = React.useState(0)
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [fileName, setFileName] = React.useState(false)
  const [overviewUrl, setOverviewUrl] = React.useState(false)
  var mainCanvas = props.main.mainCanvas
  
  const openRef = useRef();
  var bgImgCanvas = props.main.bgImgCanvas

  const onSet = async ()=>{
    if(overviewUrl){
      props.setLoading(true)
      await saveSetup(overviewUrl);
      fetchmaplist();
    }
    onOk();
  }


  const fetchmaplist=async (save_id=false)=>{ 
    var url = `${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`                
    }
    axiosInstance.get(url).then(res=>{
      console.log(res.data)
      if(!res.data || (res.data && res.data.length == 0)){
          props.setLoading(false);
      }
      var tmp = [...res.data];
      var lastFile = null

      var active_booth_file = props.main.active_booth_file
      for(var i = 0; i <tmp.length; i++){
        if(tmp[i]['KEY_ID'] == active_booth_file.KEY_ID){
            tmp[i] = {...tmp[i], active:true};
        }
      }
      
      console.log("booth fetched")
      props.setBoothFileList(tmp)
      props.setLoading(false)
    }).catch(error => {
        props.setLoading(false)
    })           
    
  }

  useEffect(() => {
    if(open){
      setTimeout(()=>{        
        init();        
        animate();

        let boothFileList = props.main.boothFileList
        for(var i = 0; i <boothFileList.length; i++){    
          let boothFile = boothFileList[i]
            let mapJs = boothFile?.['MAPJS'];
            let overview = false
            if(mapJs && mapJs != ''){
                try{
                    mapJs = JSON.parse(mapJs);
                }
                catch(e){
                
                }
                if(mapJs?.overview){
                    overview = true
                }
                else{
                }
            }
            if(overview){
              setFactor(mapJs.scale?mapJs.scale:1)
              loadModel(mapJs.url, mapJs.fileName, mapJs.scale?mapJs.scale:1);
              break;
            }
        }
      }, 500)
    }
  }, [open])

  const init=()=> {
    if(mainCanvasRef.current){
        // const poly1 = [[[0,0],[2,0],[0,2],[0,0]]]
        // const poly2 = [[[-1,0],[1,0],[0,1],[-1,0]]]

        // const poly3 = polygonClipping.union       (poly1, poly2 /* , poly3, ... */)
        // console.log("poly3", poly3);
        
        reset();
        // camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 5000 );
        var aspect =  g_mapWidth/g_mapHeight
        var D = 150
        scene = new THREE.Scene()
        scene.background = new THREE.Color( 0xffffff );
        // camera = new THREE.OrthographicCamera(-D*aspect, D*aspect, D, -D, 1, 5000)
        // camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 60, Math.max(g_mapWidth, g_mapHeight)*50 );
        camera = new THREE.PerspectiveCamera( 45, g_mapWidth / g_mapHeight, 1, Math.max(g_mapWidth, g_mapHeight)*50 );
        camera.position.set(g_mapWidth/10 , 200,  g_mapHeight)
        camera.lookAt(scene.position)

        

        // scene.background = new THREE.Color( 0xa0a0a0 );
        // scene.fog = new THREE.Fog( 0xa0a0a0, 200, 3000 );


  

        const hemiLight = new THREE.HemisphereLight( 0xa0a0a0, 0x444444 );
        hemiLight.position.set( 0, 200, 100 );
        scene.add( hemiLight );

        const directionalLight = new THREE.DirectionalLight( 0xf5f5ff );
        directionalLight.position.set( 0, 200, 100 );
        directionalLight.castShadow = true;
        // directionalLight.shadow.camera.top = 0;
        // directionalLight.shadow.camera.bottom = 0;
        // directionalLight.shadow.camera.left = 0;
        // directionalLight.shadow.camera.right = 0;
        scene.add( directionalLight );

        
        ground = new THREE.Mesh( new THREE.PlaneGeometry( g_mapWidth*20, g_mapHeight*20 ), new THREE.MeshPhongMaterial( { color: 0xffffff, depthWrite: false } ) );
        ground.rotation.x = - Math.PI / 2;
        ground.receiveShadow = true;
        scene.add( ground );

        // var boxSize = Math.max(g_mapWidth, g_mapHeight);
        // var division = 10
        // const grid = new THREE.GridHelper( boxSize, division, 0x000000, 0x000000 );
        // grid.material.opacity = 0.1;
        // grid.material.transparent = true;
        // grid.position.x = 0;
        // grid.position.y = -0;
        // grid.position.y = 0;
        // grid.position.z =0;
        // scene.add( grid );


        const loader = new THREE.ObjectLoader();

        renderer = new THREE.WebGLRenderer( { antialias: true } );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( g_mapWidth,g_mapHeight );
        renderer.shadowMap.enabled = true;
        renderer.outputEncoding = THREE.sRGBEncoding;
        // renderer.toneMapping = THREE.ACESFilmicToneMapping;
        // renderer.toneMappingExposure = 1;
        renderer.gammaOutput = true;
        renderer.gammaFactor = 2.2;
        // renderer.physicallyCorrectLights=true
        //
        
        while(mainCanvasRef.current.firstChild){
          mainCanvasRef.current.removeChild(mainCanvasRef.current.firstChild)
        }
        mainCanvasRef.current.appendChild( renderer.domElement );

        controls = new MapControls( camera, renderer.domElement );
        controls.target.set( 0, 0, 0 );
        controls.enableZoom = true;
        // controls.enableRotate = false;
        controls.mouseButtons = {
            LEFT: THREE.MOUSE.PAN,
            RIGHT: THREE.MOUSE.ROTATE,
            // MIDDLE: THREE.MOUSE.DOLLY,
        }

        controls.update();

        meshes=[]
        g_initDistance = controls.target.distanceTo( controls.object.position )
    }
  }

  const animate =()=> {
    animateId = requestAnimationFrame( animate );
    // const dt = clock.getDelta();
    //     // console.log(dt);
    //     movePlayer(dt)
    //     if(g_rotateLeft){
    //         rotateLeft()
    //     }
    //     if(g_rotateRight){
    //         rotateRight()
    //     }
        if(renderer)
            renderer.render( scene, camera );
  }

  const reset=()=>{
    if(scene){    
        if(meshes && meshes.length > 0){
            for(var j = 0; j< meshes.length; j++){
                if(meshes[j].geometry)
                    meshes[j].geometry.dispose();
                if(meshes[j].material)
                    meshes[j].material.dispose();
                scene.remove(meshes[j])
            }
        }        
        meshes = [];
        for(var i in scene){
            delete scene[i]
        }
        scene = null;
    }
    if(camera){
        for(var i in camera){
            delete camera[i]
        }
        camera = null;
    }

    if(renderer){
        for(var i in renderer){
            delete renderer[i]
        }
        renderer = null;
    }
  }

  const saveSetup = async (modelUrl)=>{  
    var mapKEY_ID ;
    let boothFileList = props.main.boothFileList      
    for(var i = 0; i <boothFileList.length; i++){
      let mapJs = boothFileList[i]['MAPJS'];
      if(mapJs && mapJs != ''){
        try{
          mapJs = JSON.parse(mapJs);
        }
        catch(e){

        }
        if(mapJs.overview){
          mapKEY_ID = boothFileList[i]['KEY_ID'];
          break;
        }
      }
    }
    var MAPJS = 
    {
      url: modelUrl,
      overview: 1,
      scale: factor,
      fileName:fileName
    }
    if(!mapKEY_ID){
      mapKEY_ID = uuidv4();
    }
    var postData = [{
        DISPLAY_ORDER: 0,
        STATUS: "",
        TITLE: 'Overview',
        MAPJS: JSON.stringify(MAPJS),
        KEY_ID: mapKEY_ID,
        MIGRATE: ""
    }]
    var url = `${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`
    
    const options = {
      headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
  }
    var response = await axiosInstance.post(url, JSON.stringify(postData), options)
    if(response.status == 200){
        var result = response.data
        setLoadingFlag(false)
    }  
    setLoadingFlag(false)
    return false;
}


  const dollyIn = ()=>{
    var zoom = step2Canvas.getZoom()
    zoom = zoom * 1.3;	
    step2Canvas.zoomToPoint({ x: step2Canvas.width/2, y: step2Canvas.height/2}, zoom);
    step2Canvas.requestRenderAll();
  }
  const dollyOut = ()=>{
    var zoom = step2Canvas.getZoom()
    zoom = zoom * 0.8;	
    step2Canvas.zoomToPoint({ x: step2Canvas.width/2, y: step2Canvas.height/2}, zoom);
    step2Canvas.requestRenderAll();
  }
  const swichFullView = ()=>{    
    var mapWidth = window.innerWidth;
    var mapHeight = window.innerHeight;
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas.endX){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
          mapWidth = unit2Px(mainCanvas.mapWidth?mainCanvas.mapWidth:0, convertRateX, mainCanvas.mapUnit)
          mapHeight = unit2Px(mainCanvas.mapHeight?mainCanvas.mapHeight:0, convertRateX, mainCanvas.mapUnit)
        }
    }
    if(mapWidth && mapHeight){
        var zoomX = (642)/(mapWidth)
        var zoomY = (500)/(mapHeight)
        var zoom = Math.min(zoomX, zoomY)*0.95
        step2Canvas.viewportTransform[0] = zoom;
        step2Canvas.viewportTransform[3] = zoom;
        step2Canvas.viewportTransform[4] = 0;
        step2Canvas.viewportTransform[5] = 0;

        step2Canvas.requestRenderAll();
        if(Math.min(zoomX, zoomY) > 0){
            var deltaX = (642 - mapWidth*zoom)/2;
            var deltaY = (500 - mapHeight*zoom)/2;
            var delta = new fabric.Point(deltaX, 35);
            step2Canvas.relativePan(delta);
        }


    }
  }
  const onMouseDown = (opt, that)=>{           
    var evt = opt.e;
    g_isDragging = true; 
    that.selection = false;
    that.lastPosX = evt.clientX;
    that.lastPosY = evt.clientY;
  }


  useEffect( async () => {
    if(boothModel){
      
      var box = new THREE.Box3().setFromObject( boothModel );
      let measure = new THREE.Vector3();
      box.getSize(measure)
      let width = measure.x/boothModel.scale.x
      let height = measure.z/boothModel.scale.x
      let rate =Math.min(g_mapWidth/width/1.2, g_mapHeight/height/1.2)
      let scale = (factor?factor:1) * rate
      boothModel.scale.set(scale, scale, scale)
      
      boothModel.position.x = -width*scale/2
      boothModel.position.y = 0;
      boothModel.position.z = height*scale/2


      var newGeometry = new THREE.PlaneGeometry(width*scale*1.2, height*scale*1.2);
      ground.geometry.dispose(); // Dispose of the old geometry
      ground.geometry = newGeometry;
      ground.rotation.x = - Math.PI / 2;


    }
  }, [factor])
  
  const loadModel=async (url, fileName, factor = 1)=>{
    setLoadingFlag(true)
    const loader = new GLTFLoader();
    try{
      var model = await loader.loadAsync( `${url}`);
    }
    catch(e){
      console.log("load error", e)      
      setLoadingFlag(false)
    }
    if(model){
        boothModel = model.scene
        console.log("boothModel", boothModel)
        var box = new THREE.Box3().setFromObject( boothModel );
        let measure = new THREE.Vector3();
        box.getSize(measure)
        setImageWidth_Ft(measure.x)
        setImageHeight_Ft(measure.z)
        setOverviewUrl(url)
        setFileName(fileName)
        var rate =1
        var scale = factor?factor:1;
        if(measure.x > 0 && measure.z>0){
          rate =Math.min(g_mapWidth/measure.x/1.2, g_mapHeight/measure.z/1.2)
          scale = rate*scale
          boothModel.scale.set(scale, scale, scale)
        }
        // var model = boothModel.clone()
        var newGeometry = new THREE.PlaneGeometry(measure.x*scale*1.2, measure.z*scale*1.2);
        ground.geometry.dispose(); // Dispose of the old geometry
        ground.geometry = newGeometry;
        ground.rotation.x = - Math.PI / 2;

        boothModel.position.x = -measure.x*scale/2
        boothModel.position.y = 0;
        boothModel.position.z = measure.z*scale/2

        
        var box1 = new THREE.Box3().setFromObject( ground );
        let measure1 = new THREE.Vector3();
        box1.getSize(measure1)
        ground.position.x = 0
        ground.position.y = 0;
        ground.position.z = 0
        

        scene.add(boothModel)
        meshes.push(boothModel)     
        setLoadingFlag(false)                
    }
    else{        
      setLoadingFlag(false)
    }
  }

  const onChangeFile = async (e)=>{
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    var file = openRef.current.files[0]
    var filename = file.name
    if(file){
      setFileName(filename)
      if(1){
        setFactor(1)
        var filename = file.name
        const reader = new FileReader();
        var data = file.slice(0, file.size);
        var filePath = `map_overview/${uuidv4()}`
        const formData = new FormData();
        const fileType = file.type || "application/octet-stream"; 
        const newFile = new File([file], file.name, {
            type: fileType,
            lastModified: file.lastModified
        });
        formData.append('file', newFile); 
        formData.append('filePath', filePath);
        var url = `${S3_URL()}uploadfile`
        setLoadingFlag(true)
        const headers = {headers: {'Content-Type': 'multipart/form-data'}};
        try{
          const response = await axiosInstance.post(url, formData, headers);
          if(response.status == 200){
            modelUrl = `${S3_URL()}s3/${filePath}`
            loadModel(modelUrl, filename, 1)
              
          }
          else
            setLoadingFlag(false)
        }
        catch(e){
          setLoadingFlag(false)
        }
      }
      openRef.current.value = null;
    }
  }

  const moveX = (direction)=>{
    setOffsetX_Ft(g_offsetX_Ft*1+direction)
  }

  const moveY = (direction)=>{
    setOffsetY_Ft(g_offsetY_Ft*1+direction)
  }

  return (
    <Dialog
      id="background-modal"
      fullWidth={true}
      maxWidth={"lg"}      
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'background-dialog-title'}}
      aria-labelledby="background-dialog-title"
      open={open}>
      <DialogTitle id='background-dialog-title' style={{display:'flex', alignItems:'center'}}><InsertPhotoOutlinedIcon style={{marginRight: 10}}/>Overview</DialogTitle>
      <DialogContent>
        <Box>
            <Box mt={4}>
              <Grid
                container
                spacing={2}
                rowSpacing = {2}
              >
                <Grid item md={12}>
                  <div>
                    <FormControl>
                      <div>
                        <Button variant="contained" onClick={()=>{openRef.current.click()}} color="primary" endIcon = {<FileUpload/>}>
                          Upload
                        </Button>
                        <input type="file" ref={openRef} style={{display:"none"}} onChange={(e)=>onChangeFile(e)}  accept='.glb,.gltf'/>
                        
                      </div>
                    </FormControl>
                    {overviewUrl &&
                    <FormControl sx={{marginLeft:'10px'}}>
                      <Button variant="contained" Download={fileName} href={overviewUrl} color="primary" endIcon = {<DownloadOutlinedIcon/>}>
                        Download
                      </Button>                      
                    </FormControl>
                    }             
                    {/* <FormControl sx={{marginLeft:'10px'}}>
                      <Button variant="contained" onClick={()=>{setImageUrl(null); }} color="primary" endIcon = {<DeleteForeverOutlinedIcon/>}>
                        Delete
                      </Button>
                      
                    </FormControl> */}
                  </div>                  
                  {
                    fileName&& 
                      <Box mb={1} style={{fontWeight:'15px'}}>
                        {fileName}
                      </Box>
                  }
                </Grid>
                
              </Grid>
            </Box>
            <Box mt={4}>
              <Grid
                container
                spacing={1}
                rowSpacing = {1}
              >
                <Grid item md={12}>
                  <Grid
                      container
                      spacing={2}
                      rowSpacing = {4}
                  >
                    <Grid item md={9}>
                      <div ref={mainCanvasRef} className='overview-modal-convas'>

                      </div>
                    </Grid>              
                    <Grid item md={3}>
                      <Box pl={2} style={{borderLeft:'1px solid rgb(237 237 237)', height:'100%'}}>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Width"
                            defaultValue="0"
                            value={imageWidth_Ft}
                            onChange={(e)=>setImageWidth(e.target.value)}
                            disabled={true}
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Height"
                            defaultValue="0"
                            value={imageHeight_Ft}
                            onChange={(e)=>setImageHeight(e.target.value)}
                            disabled={true}
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Scale"
                            defaultValue="0"
                            value={factor}
                            onChange={(e)=>setFactor(e.target.value)}
                          />
                        </Box>
                        
                        {/* <div className="set-bg-controls" style={{display:'flex', marginTop:'15px', justifyContent:'center'}}>
                            <Button title="Zoom In" onClick={()=>{dollyIn()}}><ZoomInIcon/></Button>
                            <Button style={{ marginLeft: '5px' }}  title="Zoom Out" onClick={()=>{dollyOut()}}><ZoomOutIcon/></Button>
                            <Button style={{ marginLeft: '5px' }}  title="Auto Fit on Screen" onClick={()=>{swichFullView()}}><FullscreenIcon/></Button>
                        </div> */}
                        {/* <Box className="pan" sx={{mt:'15px'}}>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, mb: '5px'}} size="small" type="button" 
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  moveY(-1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <KeyboardArrowUpOutlinedIcon />
                            </IconButton>
                          </Box>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, marginRight:'14px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  console.log("moveX")
                                  moveX(-1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <ChevronLeftOutlinedIcon />
                            </IconButton>
                            <IconButton sx={{p:0, marginLeft:'14px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  moveX(1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <ChevronRightOutlinedIcon />
                            </IconButton>
                          </Box>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, mt:'5px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  console.log("moveX")
                                  moveY(1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <KeyboardArrowDownOutlinedIcon />
                            </IconButton>
                          </Box>
                        </Box> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                
              </Grid>
            </Box>           
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={()=>onSet()}>
          <b>Set</b>
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <LoadingOverlay
        active={loadingFlag}
        styles={{
          // spinner: (base) => ({
          // ...base,
          // width: '50px',
          // '& svg circle': {
          //     stroke: '#1976d2'
          // }
          // }),
          overlay: (base) => ({
          ...base,
          fontSize:'18px',
          color: 'rgb(5, 37, 51)',
          // background: 'rgb(229 229 229 / 92%)',
          background: 'transparent',
          position:'fixed',
          zIndex:1000000000
          })
        }}
        spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
        >
      </LoadingOverlay> 
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading:  data=>dispatch(setLoading(data)),
      setBoothHistory: data=>dispatch(setBoothHistory(data)),
      setBoothFileList:  data=>dispatch(setBoothFileList(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(OverviewModal);