import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { countries, API_URL } from '../../config.js'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import qs, { parse } from 'qs'
import axios from 'axios';
import {PaperComponent} from '../../utils/Common'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import Draggable from 'react-draggable';
const emails = ['username@gmail.com', 'user02@gmail.com'];
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AttachModal(props) {
  let query = useQuery();
  const { onClose, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [sales_form, setSalesForm] = React.useState('');
  const [step, setStep] = React.useState(1);
  const [autoCombine, setAutoCombine] = React.useState(false)
  const [company, setCompany] = React.useState(null);
  const [contact, setContact] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [saleforms, setSaleforms] = React.useState([]);
  const [saleitems, setSaleitems] = React.useState([]);
  const [boothSaleItems, setBoothSaleItems] = React.useState([]);
  const filter = createFilterOptions();

  // const companies = [
  //   { label: 'The Shawshank Redemption', year: 1994 },
  //   { label: 'The Godfather', year: 1972 },
  // ];
  const handleChangeSalesForm = (event) => {
    setSalesForm(event.target.value);
  };

  useEffect(() => {
    setStep(1);
    setAutoCombine(false)
  }, [open])

  const updateBoothSaleItems = (booths)=>{
    var newBoothSaleItems = [];
    if(booths){
      for(var i = 0; i< booths.length; i++){
        newBoothSaleItems.push(
          [
            {
              saleitem: null,
              saleitem_name: null,
              saleitem_price: null,
              booth: booths[i]
            }
          ]
        )
      }
      setBoothSaleItems(newBoothSaleItems);
    }
  }
  useEffect(() => {
    if(props.boothTexts){
      updateBoothSaleItems(props.boothTexts)
    }
  }, [props.boothTexts])
  
  

  const insertBoothSaleInfo = (boothIndex, rowIndex)=>{ 
    var booths = props.boothTexts
    var emptyrow = {
      saleitem: null,
      saleitem_name: null,
      saleitem_price: null,
      booth: booths[boothIndex]
    }   
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows = [...rows.slice(0, rowIndex), emptyrow, ...rows.slice(rowIndex)]
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }
  const removeBoothSaleInfo = (boothIndex, rowIndex)=>{
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows = [...rows.slice(0, rowIndex), ...rows.slice(rowIndex+1)]
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }

  const updateBoothSaleInfo = (boothIndex, rowIndex, key, value)=>{    
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows[rowIndex]= {...rows[rowIndex], [key]:value} ;
    if(key == "saleitem" && value){
      for(var i = 0; i < saleitems.length; i++){
        if(saleitems[i]._id == value._id){
          rows[rowIndex]= {...rows[rowIndex], saleitem_price:saleitems[i].price} ;
        }
      }
    }
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }

  

  useEffect(() => {
    if(step == 1){
      fetchSaleforms();
    }
    else if(step == 2){
    }
    else if(step == 3){
      fetchCompanies();
    }
    else if(step == 4){
      fetchContacts();
    }
    else if(step == 5){
      fetchSaleitems();
    }
  }, [step])
  
  const fetchSaleforms = ()=>{
    // axios.get('/getsaleforms').then(res=>{
    //   if(res.data)
    //     setSaleforms(res.data);
    //     if(res.data.length == 1){
    //       setSalesForm(res.data[0]._id)
    //     }
    // }).catch(error => {
    //     console.log("error", error);
    // })
  }

  const fetchSaleitems = ()=>{
    axios.get('/getsaleitems').then(res=>{
      if(res.data){
        console.log(res.data)
        setSaleitems(res.data);
      }
    }).catch(error => {
        console.log("error", error);
    })
  }
  
  const fetchCompanies = ()=>{
    var boothFileList = props.main.boothFileList;
    
    
    axios.get(`/getcompanies?booth_id=${query.get("map")}`).then(res=>{
      if(res.data){
        var newValues = [];
        for(var i = 0; i< res.data.length; i++){
          var item = res.data[i]
          var saledBooths = null
          if(item['saledBooths'] && item['saledBooths'] !=""){
            saledBooths = item['saledBooths'];
          }
          newValues.push(
            { ...item, label: (item['name']?item['name']:'')+(saledBooths?` (Booth: ${saledBooths})`:'')+" "+(item['address1']?item['address1']:'')+" "+(item['address2']?item['address2']:"")+" "+(item['city']?item['city']:'')+" "+(item['zip']?item['zip']:'')+" "+(item['state']?item['state']:'')+" "+(item['code']?item['code']:'')+" "+(item['country']?item['country']:'') },
          )
        }
        setCompanies(newValues);
      }
    }).catch(error => {
        console.log("error", error);
    })
  }
  
  const fetchContacts = ()=>{
    if(company && company._id){
      axios.get(`/getcontacts?comany_id=${company._id}`).then(res=>{
        if(res.data){
          var newValues = [];
          for(var i = 0; i< res.data.length; i++){
            var item = res.data[i]
            newValues.push(
              { ...item, label: (item['name']?item['name']:'')+" "+(item['address1']?item['address1']:'')+" "+(item['address2']?item['address2']:"")+" "+(item['city']?item['city']:'')+" "+(item['zip']?item['zip']:'')+" "+(item['state']?item['state']:'')+" "+(item['code']?item['code']:'')+" "+(item['country']?item['country']:'') },
            )
          }
          setContacts(newValues);
        }
      }).catch(error => {
          console.log("error", error);
      })
    }
    else{      
      setContacts([]);
      setContact({
        'name': "",
        'address1':"",
        'address2':"",
        'city':"",
        'state':"",
        'zip':"",
        'code':"",
        'country':null
      })
    }
  }

  const setCompanyValue = (key, value)=>{
    var newCompany;
    if(company)
      newCompany = {...company, [key]:value}
    else
      newCompany = {[key]:value}
    setCompany(newCompany)
  }

  const setContactValue = (key, value)=>{
    var newContact;
    if(contact)
    newContact = {...contact, [key]:value}
    else
    newContact = {[key]:value}
    setContact(newContact)
  }


  const getCountryObject = (code)=>{
    for(var i=0; i<countries.length; i++){
      if(countries[i].code == code){
        return countries[i];
      }
    }
    return null;
  }
  
  const onChangeName = (e)=>{
    onChange(e)
  }

  const onOk = ()=>{
    
  }

  var booths = props.boothTexts;
  //console.log("rerendering")
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'buy-dialog-title'}}
      aria-labelledby="buy-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
      open={open}>
      <DialogTitle id='buy-dialog-title' style={{display:'flex', alignItems:'center'}}><ShoppingCartIcon style={{marginRight: 10}}/> 
        {
          props.reserved?
          <>Reserve</>:
          <>Buy</>
        }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        {(step == 1) &&
        <Box mt={2}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-label">Sales Form</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sales_form}
              label="Sales Form"
              onChange={handleChangeSalesForm}
            >
              {
                saleforms.map((item, index)=>{
                  return(
                    <MenuItem key={index} value={item._id}>{item.name}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
        </Box>
        }
        {(step == 2) &&
        <Box mt={2}>
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked checked={autoCombine} onChange={(e)=>setAutoCombine(e.target.checked)}/>} label="Auto-Combine Booths" />
          </FormGroup>
          <List>
            {
              booths.map((booth, index)=>{
                return(
                  <ListItem key = {index} disablePadding>
                    {/* <ListItemButton> */}
                      <ListItemText primary={"Booth: "+booth} />
                    {/* </ListItemButton> */}
                  </ListItem>
                )
              })
            }
          </List>
        </Box>
        }
        {(step == 3) &&
        <Box mt={2}>
            <Autocomplete
              disablePortal
              options={companies}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Company" />}
              value={(company&&company._id)?company:null}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  // (setCompanyValue('name',newValue))
                } else if (newValue && newValue.inputValue) {
                  setCompany({
                    'name': newValue.inputValue,
                    'address1':"",
                    'address2':"",
                    'city':"",
                    'state':"",
                    'zip':"",
                    'code':"",
                    'country':null
                  });
                }
                else{
                  setCompany(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Create New Company`,
                  });
                }
        
                return filtered;
              }}
            />
          <Grid container spacing={2}>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Company Name"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.name:""}
                onChange={(e)=>(setCompanyValue('name', e.target.value))}
                InputLabelProps = {{shrink: true}}
              />
            </Grid>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Address1"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.address1:""}
                onChange={(e)=>(setCompanyValue('address1', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Address2"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.address2:""}
                onChange={(e)=>(setCompanyValue('address2', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="City"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.city:""}
                onChange={(e)=>(setCompanyValue('city', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="State"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.state:""}
                onChange={(e)=>(setCompanyValue('state', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="Zip"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.zip:""}
                onChange={(e)=>(setCompanyValue('zip', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="Code"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.code:""}
                onChange={(e)=>(setCompanyValue('code', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={8}> 
              <Autocomplete
                sx={{ width: '100%' }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label} ({option.code})
                  </Box>
                )}
                value = {company?getCountryObject(company.country):null}
                onChange={(event, newValue)=>(setCompanyValue('country', newValue.code))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Choose a country"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        }
        {(step == 4) &&
        <Box mt={2}>
          <FormGroup>
            <Autocomplete
              disablePortal
              options={contacts}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Contact" />}
              value={(contact&&contact._id)?contact:null}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  // (setCompanyValue('name',newValue))
                } else if (newValue && newValue.inputValue) {
                  setContact({
                    'name': newValue.inputValue,
                    'address1':"",
                    'address2':"",
                    'city':"",
                    'state':"",
                    'zip':"",
                    'code':"",
                    'country':null
                  });
                }
                else{
                  setContact(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Create New Contact`,
                  });
                }
        
                return filtered;
              }}
            />
          </FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Contact Name"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.name:""}
                onChange={(e)=>(setContactValue('name', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Address1"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.address1:""}
                onChange={(e)=>(setContactValue('address1', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Address2"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.address2:""}
                onChange={(e)=>(setContactValue('address2', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="City"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.city:""}
                onChange={(e)=>(setContactValue('city', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="State"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.state:""}
                onChange={(e)=>(setContactValue('state', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="Zip"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.zip:""}
                onChange={(e)=>(setContactValue('zip', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="Code"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.code:""}
                onChange={(e)=>(setContactValue('code', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={8}> 
              <Autocomplete
                sx={{ width: '100%' }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label} ({option.code})
                  </Box>
                )}
                value = {contact?getCountryObject(contact.country):null}
                onChange={(event, newValue)=>(setContactValue('country', newValue.code))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Choose a country"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        }
        {(step == 5) &&
        <Box mt={2}>
            {
              boothSaleItems.map((boothSaleItem, index1)=>{
                return(
                  <Box
                    key={index1} mb={2}>
                    <h4>
                      Booth: {boothSaleItem[0]?boothSaleItem[0].booth:""}
                    </h4>
                    {!props.reserved &&
                    <Box display="flex" style={{alignItems:'center'}}>
                      <h4 style={{marginRight:10}}>
                        Payment Option:
                      </h4>
                      <FormControl variant="standard" style={{width: 250}}>
                        <Select
                          labelId="demo-simple-select-label"
                          // value={sales_form}
                          label="Sales Form"
                          // onChange={handleChangeSalesForm}
                        >
                          <MenuItem value='credit'>Credit Card</MenuItem>
                          <MenuItem value='stripe'>Stripe</MenuItem>
                          <MenuItem value='paypal'>Paypal</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    }
                  </Box>
                )
              })
            }
        </Box>
        }
      </DialogContent>
      <DialogActions> 
        {step >1 && 
          <Button onClick={()=>{
            if(step == 3){
             if((props.boothTexts&& props.boothTexts.length > 1)){
               setStep(step-1)
             }
             else{
               setStep(1)
             }
            }
            else
             setStep(step-1)
           }
         }>Before</Button>
        }
        {step < 5 ?
          <Button onClick={()=>{
             if(step == 1){
              if((props.boothTexts&& props.boothTexts.length > 1)){
                setStep(step+1)
              }
              else{
                setStep(3)
              }
             }
             else
              setStep(step+1)
            }
          }>Next</Button>:
          <Button variant="contained" onClick={onClose}>
            {
              props.reserved?
              <>Reserve Now</>:
              <>Buy Now</>
            }
            
          </Button>
        }
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(AttachModal);