import * as React from 'react';
import { useEffect,useState, useRef } from 'react';
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { setMainLayers, setShowGrid, setUnit, setBoothInfo, setLoading, setPricingOptions, setPremiumOptions, setDiscountOptions, setCategoryOptions,setFeeGroupOptions,setFeeCategoryOptions, setGroupOptions, setActiveBoothFile, setBoothFileList, setMechantAccount } from '../../actions/main'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { fabric } from "fabric";
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, meter2feet, feet2meter, renderBorder, setObjectBG, setObjectFontColor, px2unit, unit2Px} from '../../utils/CanvasTool'
import { convertRgbHex, assetUrl, convertRgbStringHex } from '../../utils/Common'
import rgbHex from "rgb-hex";
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import IconButton from '@mui/material/IconButton';
import CropModal from './CropModal'
import DeleteConfirm from './DeleteConfirm'
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import SetupPrice from './SetupPrice'
import SetupPremium from './SetupPremium'
import SetupDiscount from './SetupDiscount'
import { uuid4 } from './sketch_main/utils';
import { v4 as uuidv4 } from 'uuid';
import {PaperComponent} from '../../utils/Common'
import DeleteIcon from '@mui/icons-material/Delete';
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";

import { saveLog } from '../../services/service.js'
import { parseToken, listToTree, convertRgb } from '../../utils/Common'
import { getTokenCookie } from '../../utils/Common'
import ColorPicker from './ColorPicker';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const emails = ['username@gmail.com', 'user02@gmail.com'];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#b71c1cff',
  '&:hover': {
    backgroundColor: 'red',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

var first_mapWidth
var first_mapHeight
var first_gridSize
var first_showGrid
var first_snapToGrid
var first_mapUnit
var first_gridAngle
var first_mapOffsetX
var first_mapOffsetY
var first_event_home
var first_sales_form
var first_logo
var first_header_banner
var first_header_color
var handleCloseDeleteModal
var confirmDeleteModal
var eshowMapId = "63cea991e5cf5a11aed65881"
var deleteId = false
function SetupModal(props) {
  const { onClose,  onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  let query = useQuery();

  const [tabValue, setTabValue] = React.useState(0);
  const [mapUnit, setMapUnit] = React.useState("feet");
  const [gridSize, setGridSize] = React.useState(0);
  const [mapWidth, setMapWidth] = React.useState(0);
  const [mapHeight, setMapHeight] = React.useState(0);
  const [showGrid, setShowGrid] = React.useState(false);
  const [snapToGrid, setSnapToGrid] = React.useState(false);
  const [gridAngle, setGridAngle] = React.useState(0);
  const [mapOffsetX, setMapOffsetX] = React.useState(0);
  const [mapOffsetY, setMapOffsetY] = React.useState(0);
  const [event_home, setevent_home] = React.useState('');

  const [openAdd, setOpenAdd]  = React.useState(false);
  const [openEdit, setOpenEdit]  = React.useState(false);
  const [imgSrc, setImgSrc] = useState('')
  const [selectedCropItem, setSelectedTreeCropItem] = useState(null)
  const [openCropModal, setOpenCropModal] = useState(false)  
  const [openDeleteModal, setOpenDeleteModal] = useState(false)    
  // const [deleteId, setDeleteId] = useState(false)      
  const [deleteTitle, setDeleteTitle] = useState(false) 
  const [cropLogoData, setCropLogoData] = useState(null)
  const [cropHeaderBannerData, setCropHeaderBannerData] = useState(null)
  const [cropLogoPreviewData, setCropLogoPreviewData] = useState(null)
  const [cropHeaderPreviewData, setCropHeaderPreviewData] = useState(null)
  
  const [groupOptions , setBoothGroups] = React.useState([]);
  const [openAddPriceSaleItem, setOpenAddPriceSaleItem]  = React.useState(false);
  const [openEditPriceSaleItem, setOpenEditPriceSaleItem]  = React.useState(false);
  const [openAddPremiumSaleItem, setOpenAddPremiumSaleItem]  = React.useState(false);
  const [openEditPremiumSaleItem, setOpenEditPremiumSaleItem]  = React.useState(false);
  const [openAddDiscountItem, setOpenAddDiscountItem]  = React.useState(false);
  const [openEditDiscountItem, setOpenEditDiscountItem]  = React.useState(false);
  const [openEditMapItem, setOpenEditMapItem]  = React.useState(false);
  const [openAddCategoryItem, setOpenAddCategoryItem]  = React.useState(false);
  const [openEditCategoryItem, setOpenEditCategoryItem]  = React.useState(false);
  const [color, setColor] = useState('#fff')
  const [selectedColor, setSelectedColor] = useState('#fff')
  const [openColorPicker, setOpenColorPicker] = React.useState(false);
  const [editGroupItem, setEditGroupItem] = React.useState({});
  const [editPriceSaleItem, setEditPriceSaleItem] = React.useState({});
  const [editPremiumSaleItem, setEditPremiumSaleItem] = React.useState({});
  const [editDiscountItem, setEditDiscountItem] = React.useState({});
  const [editMapItem, setEditMapItem] = React.useState({});
  
  const [editCategoryItem, setEditCategoryItem] = React.useState({});
  const [parentCategoryItem, setParentCategoryItem] = React.useState({});
  const [editGroupType, setEditGroupType]= React.useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [priceFilter, setPriceFilter] = useState({})
  const [premiumFilter, setPremiumFilter] = useState({})
  const [priceCheck , setPriceCheck] = React.useState([]);
  const [premiumCheck , setPremiumCheck] = React.useState('');
  const [contract_pdf, setContracPdfName] = React.useState('');
  const [sales_form, setSalesForm] = React.useState('');
  const [saleforms, setSaleforms] = React.useState([]);
  const [expandedTree, setExpandedTree] = React.useState([]);
  const [selectedTree, setSelectedTree] = React.useState([]);
  const [disableMigrate, setDisableMigrate]= React.useState(false);
  const [headerColor, setHeaderColor]= React.useState(false);
  const [headerColorRGB, setHeaderColorRGB]= React.useState("");
  const [editMapSetup, setEditMapSetup] = React.useState(false);
  const [deleteMapTitle, setDeleteMapTitle] = React.useState("");
  const dwgRef = useRef();

  const handleToggleTree = (event, nodeIds) => {
    if (!event.target.classList.contains("collapseIcon") && event.target.nodeName !== "svg" && event.target.nodeName !== "path") {
      return;
    }
    setExpandedTree(nodeIds);
  };

  var FEET_RATE = 12
  var METER_RATE = 1//39.3701
  var mainCanvas = props.main.mainCanvas

  const fetchSetup =async (map_key)=>{
    var url = `${ESHOW_URL()}?method=getSetup&map_key=${map_key}&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getSetup&map_key=${map_key}&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    const response = await axiosInstance.get(url)
    var result = false;
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            result = data
            var forms = result.FORMS;
            setSaleforms(forms)
        }
    }
    else{
        console.log("")
    }
    return result;
  }

  const fetchEditMapSetup =async (map_key)=>{    
    setEditMapSetup(false)
    var url = `${ESHOW_URL()}?method=getSetup&map_key=${map_key}&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getSetup&map_key=${map_key}&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const response = await axiosInstance.get(url)
    var result = false;
    if(response.status == 200){
        var data = response.data
        setEditMapSetup(data)
    }
    else{
        console.log("")
    }
    return result;
  }

  const fetchMechnantAccount =async ()=>{
    {
      // axiosInstance.get(`/getmechantaccount?boothId=''&show_key=${authInfo.show_key}`).then(res=>{
      //   // console.log("getmechantaccount", res.data)     
      //   if(res.data){
      //     props.setMechantAccount(res.data)
      //   }     
      // }).catch(error => {
      //     //console.log("error", error);
      // })
      setMechantAccount(false)
    }
}

  useEffect(() => {
    if(open){
      fetchMechnantAccount()  
      fetchGroupList()
      fetchCategoryOptions()
      fetchFeeCategoryList();
      fetchFeeGroupList();
      setGridAngle(mainCanvas.gridAngle)
      var active_booth_file = props.main.active_booth_file
      if(active_booth_file){
        fetchSetup(active_booth_file.KEY_ID)
        setevent_home(active_booth_file.event_home);
        if(active_booth_file.contract_pdf){
          setContracPdfName(active_booth_file.contract_pdf)
        }
        if(active_booth_file.sales_form){
          setSalesForm(active_booth_file.sales_form)
        }
      }
      
      
      var convertRateX= Math.abs(mainCanvas.convertRateX)

      var tmpWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
      var tmpHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
      var mapWidth = 0, mapHeight = 0;
      var mapUnit = mainCanvas.mapUnit?mainCanvas.mapUnit:'feet';
      var sales_form = mainCanvas.sales_form?mainCanvas.sales_form:'';
      var unitRate  =12;
      if(convertRateX ){
        mapWidth = (tmpWidth*convertRateX/unitRate)
        mapHeight = (tmpHeight*convertRateX/unitRate)        
      }
      var tmp1 = mainCanvas.mapWidth&&mainCanvas.mapWidth*1?mainCanvas.mapWidth:mapWidth
      tmp1 = (Math.round(tmp1/10) * 10)
      var tmp2 = mainCanvas.mapHeight&&mainCanvas.mapHeight*1?mainCanvas.mapHeight:mapHeight
      tmp2 = (Math.round(tmp2/10) * 10)

      setMapWidth(tmp1)
      first_mapWidth = tmp1
      setMapHeight(tmp2)
      first_mapHeight = tmp2
      setMapUnit(mapUnit)
      first_mapUnit = mapUnit
      setSalesForm(sales_form)
      first_sales_form = sales_form
      var default_grid = 10;
      if(mapWidth > 1000){
        default_grid = 50
      }
      if(mapWidth > 10000){
        default_grid = 100
      }
      var mapOffsetX = mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0
      var mapOffsetY = mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0
      var gridSize = mainCanvas.gridSize?mainCanvas.gridSize:default_grid

      setMapOffsetX(mapOffsetX)
      first_mapOffsetX = mapOffsetX
      setMapOffsetY(mapOffsetY)
      first_mapOffsetY = mapOffsetY
      setGridSize(gridSize)
      first_gridSize = gridSize
      setShowGrid(mainCanvas.showGrid)
      first_showGrid = mainCanvas.showGrid
      setSnapToGrid(mainCanvas.snapToGrid)
      first_snapToGrid = mainCanvas.snapToGrid

      fetchShowSetup()
      
    }
  }, [open])


  const fetchShowSetup =async ()=>{
    var url = `${ESHOW_URL()}?method=getShowSetup&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getShowSetup&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    const response = await axiosInstance.get(url)
    var result = false;
    if(response.status == 200){
      var data = response.data
      if(data.SUCCESS == true){
        result = data
        if(result?.PUBLIC?.HEADER_BANNER){
          setCropHeaderPreviewData(result?.PUBLIC?.HEADER_BANNER)
          first_header_banner = result?.PUBLIC?.HEADER_BANNER;
        }
        else{
          setCropHeaderPreviewData(null)
          first_header_banner = null;
        }
        
        if(result?.PUBLIC?.HEADER_COLOR){
          setHeaderColor(result?.PUBLIC?.HEADER_COLOR)
          first_header_color = result?.PUBLIC?.HEADER_COLOR
          setHeaderColorRGB(convertRgb(result?.PUBLIC?.HEADER_COLOR))
        }
        else{
          setHeaderColor(false)
          setHeaderColorRGB("")
          first_header_color = false
        }

        if(result?.PUBLIC?.LOGO){
          setCropLogoPreviewData(assetUrl(result?.PUBLIC?.LOGO))
          first_logo = assetUrl(result?.PUBLIC?.LOGO)
        }
        else{
          setCropLogoPreviewData(null)
          first_logo = false
        }
      }

    }
    else{
        console.log("")
    }
    return result;
}

  useEffect(() => {
    if(open){
      var premiumCheck
      var priceCheck = []
      if(props.booths && props.booths[0]){
        premiumCheck = props.booths[0].get('premiumCheck')
        if(premiumCheck){
          setPremiumCheck(premiumCheck)
        }
        else{
          setPremiumCheck(null)
        }
        var tmp = props.booths[0].get('priceCheck')
        if(tmp){
          // priceCheck = JSON.parse(tmp)
          setPriceCheck(tmp)
        }
        else{
          setPriceCheck([])
        }

        var priceFilter = props.booths[0].get('priceFilter')
        if(priceFilter){
          setPriceFilter(priceFilter)
        }
        else{
          setPriceFilter({})
        }
        
        var premiumFilter = props.booths[0].get('premiumFilter')
        if(premiumFilter){
          setPremiumFilter(premiumFilter)
        }
        else{
          setPremiumFilter({})
        }
      }
    }
  }, [open])

  useEffect(() => {
    if(open){
      if(tabValue == 2){
        fetchGroupList();
      }
      else if(tabValue == 6){
        fetchCategoryOptions()
      }
    }
  }, [tabValue])
  const handleCloseSnack = ()=>{
    setOpenSnack(false);
}


  const handleChangeColorComplete = (c) => {
    // setColor(color );
    setOpenColorPicker(false);
    setColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a))
    if(editGroupType){
      // setEditGroupItem({...editGroupItem, [editGroupType]:"#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)});
      if(editGroupType == 'header'){
        setHeaderColor(`${c.rgb.r},${c.rgb.g},${c.rgb.b}`)
        setHeaderColorRGB(convertRgb(`${c.rgb.r},${c.rgb.g},${c.rgb.b}`))
      }
      else
        setEditGroupItem({...editGroupItem, [editGroupType]:`${c.rgb.r},${c.rgb.g},${c.rgb.b}`, [`${editGroupType}_RGB`]:convertRgb(`${c.rgb.r},${c.rgb.g},${c.rgb.b}`)});
    }
  };


  const fetchGroupList = async ()=>{
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=getBoothGroups&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getBoothGroups&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.length > 0){
          for(var i = 0; i < result.length; i++){
              result[i].TITLE = result[i].TITLE.trim()
          }
        }
        result.sort(function (a, b) {
          return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
        });
        props.setGroupOptions(result)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }
  const fetchPricingOptions =async ()=>{
    var activeFile;
    var boothFileList = props.main.boothFileList;
    for(var i = 0; i <boothFileList.length; i++){
        if(boothFileList[i]['active']){
            activeFile = boothFileList[i];
            break;
        }
    }
    if(activeFile) {
      props.setLoading(true)
      var tokenInfo = await parseToken(query)
      var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
      axiosInstance.get(`/getpricesalelist?boothId=${activeFile['id']}&show_key=${show_key}`).then(res=>{
        console.log(res.data)     
        if(res.data){
          props.setPricingOptions(res.data)
        }     
        props.setLoading(false)
      }).catch(error => {
          console.log("error", error);
          props.setLoading(false)
      })
    }
  }
  const fetchPremiumOptions =async ()=>{
    var activeFile;
    var boothFileList = props.main.boothFileList;
    for(var i = 0; i <boothFileList.length; i++){
        if(boothFileList[i]['active']){
            activeFile = boothFileList[i];
            break;
        }
    }
    if(activeFile) {
      props.setLoading(true)
      var tokenInfo = await parseToken(query)
      var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
      axiosInstance.get(`/getpremiumsalelist?boothId=${activeFile['id']}&show_key=${show_key}`).then(res=>{
        console.log(res.data)     
        if(res.data){
          props.setPremiumOptions(res.data)
        }     
        props.setLoading(false)
      }).catch(error => {
          console.log("error", error);
          props.setLoading(false)
      })
    }
  }

  const fetchDiscountOptions =async ()=>{
    var activeFile;
    var boothFileList = props.main.boothFileList;
    for(var i = 0; i <boothFileList.length; i++){
        if(boothFileList[i]['active']){
            activeFile = boothFileList[i];
            break;
        }
    }
    if(activeFile) {
      props.setLoading(true)
      var tokenInfo = await parseToken(query)
      var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
      axiosInstance.get(`/getdiscountlist?boothId=${activeFile['id']}&show_key=${show_key}`).then(res=>{
        console.log(res.data)     
        if(res.data){
          props.setDiscountOptions(res.data)
        }     
        props.setLoading(false)
      }).catch(error => {
          console.log("error", error);
          props.setLoading(false)
      })
    }
  }  

  const fetchCategoryOptions =async ()=>{
    var url = `${ESHOW_URL()}?method=getProductCategories&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getProductCategories&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    props.setLoading(true)
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            if(result.CATEGORIES){                  
              props.setCategoryOptions(result.CATEGORIES)
            }
        }
        else{
        }
        props.setLoading(false)
    }).catch(error => {
    })  
  }    

  const fetchFeeCategoryList =async ()=>{
    var url = `${ESHOW_URL()}?method=getFeeCategories&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getFeeCategories&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        result.sort(function (a, b) {
            return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
        });
        props.setFeeCategoryOptions(result)
      }).catch(error => {
      }).catch(error => {
          //console.log("error", error);
    }).catch(error => {
          //console.log("error", error);
    })  
  }
  
  const fetchFeeGroupList =async ()=>{
    var url = `${ESHOW_URL()}?method=getFeeGroups&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getFeeGroups&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        result.sort(function (a, b) {
            return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
        });
        props.setFeeGroupOptions(result)
    }).catch(error => {
    })  
}

  const handleChangeColor = (c, event) => {
    // setColor(color );
    setColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a))
  }
  
  const handleOpenColorPicker = (groupType, color)=>{
    setOpenColorPicker(true);
    setEditGroupType(groupType)
    setSelectedColor(color)
  }

  const onChangeUnit = (value)=>{
    if(value == 'feet'){
      mainCanvas.set({
        mapUnit:'feet'
      })
    }
    else{
      mainCanvas.set({
        mapUnit:'meter'
      })
    }
    setMapUnit(value)
  }
  const onChangeName = (e)=>{
    onChange(e)
  }
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  // rgba(25,118,210,255)
const adjustBoothColor=(editGroupItem) =>{
  //SOLD:`${0xc2},${0x18},${0x5b}`, PENDING:`${0xff},${0xeb},${0x3b}`, AVAILABLE:
  var group_KEY_ID = editGroupItem.KEY_ID
  var groups = mainCanvas.getObjects('group');  
  for(var i = 0; i < groups.length; i++){
    if(groups[i].get('class') == 'booth'){
      var exhibitors = groups[i].exhibitors
      var boothGroup = groups[i].boothGroup;
      // if(!boothGroup || boothGroup == '' || (boothGroup && !isUUID(boothGroup))){
      //     boothGroup = groups[i].boothGroup = defaultGroup
      // }
      if(group_KEY_ID == boothGroup){
        if(groups[i].holdBooth){
            var bgColor = "#dadada"
            bgColor = convertRgbHex(editGroupItem['RESERVED']);
            setObjectBG(mainCanvas, groups[i], bgColor)
        }
        else if(groups[i].sold){
            var bgColor = "#dadada"
            bgColor = convertRgbHex(editGroupItem[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
            setObjectBG(mainCanvas, groups[i], bgColor)
        }
        else{
            var bgColor = "#dadada"
            if(groups[i].priceCheck && groups[i].priceCheck.length > 0){
                bgColor = convertRgbHex(editGroupItem['AVAILABLE']);
            }
            setObjectBG(mainCanvas, groups[i], bgColor)
        }
        var fontColor = "black"
        if(editGroupItem['FONT_COLOR'] && editGroupItem['FONT_COLOR']!=""){
          fontColor = convertRgbHex(editGroupItem['FONT_COLOR']);
        }
        else{
          fontColor = "black"
        }
        setObjectFontColor(mainCanvas, groups[i], fontColor)
      }
    }
  }
  mainCanvas.renderAll()
}

const resetBoothColor=(editGroupItem) =>{
  //SOLD:`${0xc2},${0x18},${0x5b}`, PENDING:`${0xff},${0xeb},${0x3b}`, AVAILABLE:
  
}


const onDeleteDwg = async (e)=>{
  var settingData = {
    ...editMapSetup,
    DWG:{
      ...editMapSetup.DWG, 
      DWG_PATH:false,
      DWG_NAME:false,
      // COVERT_OFFSET_X:0,
      // COVERT_OFFSET_Y:0,
    }
  }
  
  var postData = {
      SUCCESS: true,
      ...settingData
  }
  const options = {
      headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
  }
  var url = `${ESHOW_URL()}?method=putSetup&map_key=${editMapItem.KEY_ID}&token=${getTokenCookie()}`
  var response1 = await axiosInstance.post(url, JSON.stringify(postData), options)
  if(response1.status == 200){
      // var result = response1.data
      setEditMapSetup(settingData)
      props.setLoading(false)
      var activeFile = {...props.main.active_booth_file,
        dwgPath:settingData?.DWG.DWG_PATH, 
        dwgName:settingData?.DWG.DWG_NAME,
        // convertOffsetX: settingData?.DWG?.COVERT_OFFSET_X,
        // convertOffsetY: settingData?.DWG?.COVERT_OFFSET_Y,
      }           
      // mainCanvas.set({
      //   convertOffsetX, convertOffsetY
      // })
      props.setActiveBoothFile(activeFile)
  }
}

const onChangeDwg = (e)=>{
  var file = dwgRef.current.files[0]      
  setTimeout(async()=>{
      if(file){
          var filename = file.name
          let last_dot = filename.lastIndexOf('.')
          let ext = filename.slice(last_dot + 1)
          props.setLoading(true)
          var data = file.slice(0, file.size);
          var filePath = `dwg/${uuidv4()}.${ext}`
          var now = new Date();
          let dwgName = filename.slice(0,last_dot-1)+""+moment().format("YYYYMMDD_HHmmss")+"."+ext          
          const formData = new FormData();
          const fileType = file.type || "application/octet-stream"; 
          const newFile = new File([file], file.name, {
              type: fileType,
              lastModified: file.lastModified
          });
          formData.append('file', newFile); 
          formData.append('filePath', filePath);
          var url = `${S3_URL()}uploadfile`        
          const headers = {headers: {'Content-Type': 'multipart/form-data'}};
          try{
            const response = await axiosInstance.post(url, formData, headers);
            if(response.status == 200){
              let dwgPath = `${S3_URL()}s3/${filePath}`
              var formdata = 
              {
                  "action":"svg_creation",
                  "converter":"AutoXchange AX2020",
                  "version":"V1.00",
                  "contentType":"file",
                  "contentLocation":`${dwgPath}`,
                  "contentFormat":"DWG",
                  "contentUsername":"",
                  "contentPassword":"",
                  "userLabel":"fromCADViewerJS",
                  "contentResponse":"stream",
                  "leaveStreamOnServer":0,
                  "parameters":[
                      {"paramName":"f",
                      "paramValue":"svg"},
                      // {"paramName":"RL",
                      // "paramValue":"RM_"},
                      // {"paramName":"TL","paramValue":"RM_TXT"},
                      // {"paramName":"LA","paramValue":""},
                      // {"paramName":"last","paramValue":""},
                      {"paramName":"model","paramValue":""},
                      // {"paramName":"extents","paramValue":""},
                      {"paramName":"fpath","paramValue":"/converters/ax2022/linux/fonts/"},
                      {"paramName":"WIDTH","paramValue":"10000"},
                      {"paramName":"HEIGHT","paramValue":"10000"},
                  ]
              }
              var data = qs.stringify({
                  request: JSON.stringify(formdata)
                });
              // var bodyFormData = new FormData();
              // bodyFormData.append('request', JSON.stringify(formdata));
              const options = {
                  headers: {"content-type": 'application/x-www-form-urlencoded'}
              }
              axiosInstance.post('/callapiconversion', data, options).then(res=>{
                  const convertedSvgInfo = res.data;
                  if(convertedSvgInfo){
                    axiosInstance.get(convertedSvgInfo.contentStreamData, { headers: {  } }).then(async res=>{
                        const result = res.data;
                        const parser = new DOMParser();
                        const svgDoc = parser.parseFromString(result, "application/xml");
                        // var svgDoc = doc.contentDocument;
                        var svgStr = svgDoc.getElementsByTagName('svg')[0].outerHTML;
                        var drawermain = SVG(svgStr);
                        var dimension_str = drawermain.find("#cadviewer_drawingCoordinates").text();
                        var units_str = drawermain.find("#cadviewer_worldUnits").text();
                        var dimension = null;
                        var convertRateX = 1;
                        var convertRateY = 1;
                        var svgToWorldUnits = 1;
                        var convertOffsetX = 0;
                        var convertOffsetY = 0;
                        if(units_str && units_str.length>0){
                          units_str[0] = units_str[0].replaceAll(". ", "");
                          var units = JSON.parse(units_str[0]);
                          var globalOrigin = units['globalOrigin']
                          if(globalOrigin['svgToWorldUnits']){
                              convertRateX = convertRateY = globalOrigin['svgToWorldUnits']
                              svgToWorldUnits = globalOrigin['svgToWorldUnits']
                          }
                          else{
                              convertRateX = convertRateY = units['svgToWorldUnits']
                              svgToWorldUnits = units['svgToWorldUnits']
                          }
                        }
                        if(dimension_str && dimension_str.length>0){
                          dimension_str[0] = dimension_str[0].replaceAll(". ", "");
                          dimension = JSON.parse(dimension_str[0])
                          ////console.log("dimension", dimension)
                          var dwg_info = dimension.DWG;
                          var svg_info = dimension.SVG;
              
                          convertOffsetX = dwg_info.lowerLeft.x + svg_info.lowerLeft.x*svgToWorldUnits
                          convertOffsetY = dwg_info.upperRight.y - svg_info.lowerLeft.y*svgToWorldUnits
              
                        }

                        console.log("svgStr", svgStr)
                        var settingData = {
                          ...editMapSetup,
                          DWG:{
                            ...editMapSetup.DWG, 
                            DWG_PATH:`${dwgPath}`,
                            DWG_NAME:dwgName,
                            // COVERT_OFFSET_X:convertOffsetX,
                            // COVERT_OFFSET_Y:convertOffsetY,
                          }
                        }
                        var active_booth_file = props.main.active_booth_file
                        if(active_booth_file.version*1 != 2){
                          settingData.DWG.COVERT_OFFSET_X = convertOffsetX;
                          settingData.DWG.COVERT_OFFSET_Y = convertOffsetY;
                        }
                        
                        var postData = {
                            SUCCESS: true,
                            ...settingData
                        }
                        const options = {
                            headers: {
                                'body': '[{"company:","test"}]', 
                                'Content-Type': 'application/json'
                              }
                        }
                        var url = `${ESHOW_URL()}?method=putSetup&map_key=${editMapItem.KEY_ID}&token=${getTokenCookie()}`
                        var response1 = await axiosInstance.post(url, JSON.stringify(postData), options)
                        if(response1.status == 200){
                            // var result = response1.data
                            setEditMapSetup(settingData)
                            props.setLoading(false)
                            var activeFile = {...props.main.active_booth_file,
                              dwgPath:settingData?.DWG.DWG_PATH, 
                              dwgName:settingData?.DWG.DWG_NAME,
                              // convertOffsetX: settingData?.DWG?.COVERT_OFFSET_X,
                              // convertOffsetY: settingData?.DWG?.COVERT_OFFSET_Y,
                            }       
                            
                            if(activeFile.version*1 != 2){
                              activeFile = {...activeFile,
                                convertOffsetX: settingData?.DWG?.COVERT_OFFSET_X,
                                convertOffsetY: settingData?.DWG?.COVERT_OFFSET_Y,
                              }                              
                              mainCanvas.set({
                                convertOffsetX, convertOffsetY
                              })
                            }    
                            props.setActiveBoothFile(activeFile)
                        }
            
                    }).catch(error => {
                        ////console.log("error", error);
                    })
                        
                }    
              }).catch(error => {
                  ////console.log("error", error);
              })
            }
          }
          catch(e){
          }
          dwgRef.current.value = null;
      }
  }, 300)
}

const saveBoothGroup = async ()=>{

    var postData = editGroupItem
    postData.SOLD = convertRgbStringHex(postData.SOLD_RGB)
    postData.PENDING = convertRgbStringHex(postData.PENDING_RGB)
    postData.AVAILABLE = convertRgbStringHex(postData.AVAILABLE_RGB)
    postData.RESERVED = convertRgbStringHex(postData.RESERVED_RGB)
    postData.FONT_COLOR = convertRgbStringHex(postData.FONT_COLOR_RGB)
    console.log("postData", postData);
    delete postData.SOLD_RGB
    delete postData.PENDING_RGB
    delete postData.AVAILABLE_RGB
    delete postData.RESERVED_RGB
    delete postData.FONT_COLOR_RGB
    var formdata = {
      query:`${ESHOW_URL()}?method=putBoothGroups&token=${getTokenCookie()}`,
      returnResponse: true,
      postData: [postData]
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putBoothGroups&token=${getTokenCookie()}`
    axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
        const response = res.data;
        if(response.SUCCESS){
          setOpenSnack(true);
          if(openAdd){      
            setSnackMsg("Added successfully");
          }
          else{
            setSnackMsg("Updated successfully");
          }
          adjustBoothColor(editGroupItem)
        }
        else{
          setOpenSnack(true);
          setSnackMsg("Something went wrong");
        }        
            
        fetchGroupList();
        setOpenAdd(false)
        setOpenEdit(false)
        
    }).catch(error => {
        console.log("error", error);
    })
    
  }

  const confirmDeleteGroupModal = ()=>{
    if(deleteId) {
      var postData = 
      {
        KEY_ID:deleteId,
        ACTIVE:0
      }
      var formdata = {
        query:`${ESHOW_URL()}?method=putBoothGroups&token=${getTokenCookie()}`,
        returnResponse: true,
        postData: [postData]
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }
      var url = `${ESHOW_URL()}?method=putBoothGroups&token=${getTokenCookie()}`
      axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
          const response = res.data;
          if(response.SUCCESS ){
            setOpenSnack(true);
            setSnackMsg("Deleted successfully");
            fetchGroupList();
            // setDeleteId(false)
            deleteId = false
            setOpenDeleteModal(false)
          }
          else{
            setOpenSnack(true);
            setSnackMsg("Something went wrong");
          }
          setOpenAdd(false)
          setOpenEdit(false)
          resetBoothColor(editGroupItem)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
  }

  
  const handleCloseDeleteGroupModal=()=>{
    setOpenDeleteModal(false)
  }

  const fetchmaplist=async (save_id=false)=>{ 
    var url = `${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
      console.log(res.data)
      if(!res.data || (res.data && res.data.length == 0)){
          props.setLoading(false);
      }
      var tmp = [...res.data];
      var lastFile = null

      var active_booth_file = props.main.active_booth_file
      for(var i = 0; i <tmp.length; i++){
        if(tmp[i]['KEY_ID'] == active_booth_file.KEY_ID){
            tmp[i] = {...tmp[i], active:true};
        }
      }
      
      console.log("booth fetched")
      props.setBoothFileList(tmp)
      props.setLoading(false)
    }).catch(error => {
        props.setLoading(false)
    })           
    
  }

  const saveMechantAccount = async ()=>{
    var url = `/updatemechantaccount`
    var mechantAccount = props.main.mechantAccount
    var formdata = 
    {
        // ...active_booth_file,
        _id: mechantAccount?._id?mechantAccount?._id:'', 
        XWebID: mechantAccount?.XWebID?mechantAccount?.XWebID:'',      
        TID: mechantAccount?.TID?mechantAccount?.TID:'' ,      
        AuthKey: mechantAccount?.AuthKey?mechantAccount?.AuthKey:''  ,      
        Transactions_API_Key: mechantAccount?.Transactions_API_Key?mechantAccount?.Transactions_API_Key:''  ,      
        Transactions_API_Secret_Key: mechantAccount?.Transactions_API_Secret_Key?mechantAccount?.Transactions_API_Secret_Key:''  ,      
        PayFields_API_Key: mechantAccount?.PayFields_API_Key?mechantAccount?.PayFields_API_Key:''          
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    props.setLoading(true)
    axiosInstance.post(url, data, options).then(res=>{
        const result = res.data;
        if(result.status == "success"){
            setOpenSnack(true);
            setSnackMsg("Updated mechant account successfully");
            props.setLoading(false)
        }
        else{
            setOpenSnack(true);
            setSnackMsg("Failed to update");
            props.setLoading(false)
        }
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const saveMapItem = async ()=>{
    props.setLoading(true)
    var mapKEY_ID = editMapItem.KEY_ID;
    var postData = [{
        DISPLAY_ORDER: editMapItem.DISPLAY_ORDER?editMapItem.DISPLAY_ORDER*1:0,
        STATUS: "",
        TITLE: editMapItem.TITLE?editMapItem.TITLE:'',
        KEY_ID: mapKEY_ID
    }]
    var formdata = {
        query:`${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`,
        postData: postData
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
      const result = res.data;
      if(result.SUCCESS){
          // props.setLoading(false)
          fetchmaplist();
      }
      else{
          setOpenSnack(true);
          setSnackMsg("Failed to update");
          props.setLoading(false)
      }
    }).catch(error => {
        //console.log("error", error);
        props.setLoading(false)
    })  
  }

  const saveCategoryItem = async ()=>{
    var postData = editCategoryItem
    console.log("postData", postData);
    var url = `${ESHOW_URL()}?method=putProductCategories&token=${getTokenCookie()}`
    var formdata = {
      query:`${ESHOW_URL()}?method=putProductCategories&token=${getTokenCookie()}`,
      returnResponse: true,
      postData: {
        SUCCESS: true,
        CATEGORIES: [postData]
      }
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
        const response = res.data;
        if(response.SUCCESS){
          setOpenSnack(true);
          setSnackMsg(response.MESSAGE?response.MESSAGE:"Success");
          fetchCategoryOptions();
          fetchFeeCategoryList();
          fetchFeeGroupList();
          setOpenAddCategoryItem(false)
          setOpenEditCategoryItem(false)
        }
        else{
          setOpenSnack(true);
          setSnackMsg(response.MESSAGE?response.MESSAGE:"Something went wrong");
        }
    }).catch(error => {
        console.log("error", error);
    })
  }

  const deleteCategoryItem= ()=>{
    var postData = {
      ACTIVE: "0",
      KEY_ID: editCategoryItem.KEY_ID
    }
    var url = `${ESHOW_URL()}?method=putProductCategories&token=${getTokenCookie()}`
    var formdata = {
      query:`${ESHOW_URL()}?method=putProductCategories&token=${getTokenCookie()}`,
      postData: {
        SUCCESS: true,
        CATEGORIES: [postData]
      },
      returnResponse:true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
        const response = res.data;
        if(response.SUCCESS){
          setOpenSnack(true);
          setSnackMsg(response.MESSAGE?response.MESSAGE:"Success");
          fetchCategoryOptions();
        }
        else{
          setOpenSnack(true);
          setSnackMsg(response.MESSAGE?response.MESSAGE:"Something went wrong");
        }
        setOpenAddCategoryItem(false)
        setOpenEditCategoryItem(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const onOk = async (e)=>{
    props.setUnit(mapUnit)
    props.setBoothInfo({event_home})
    var logo_file = cropLogoPreviewData;
    if(cropLogoData) {
      var filename = "logo.png"
        // const reader = new FileReader();
      var data = cropLogoData.slice(0, cropLogoData.size);
      var filePath = `map_log/${uuidv4()}`
      const formData = new FormData();
      const fileType = cropLogoData.type || "image/png"; 
      const blob = cropLogoData.type ? cropLogoData : new Blob([cropLogoData], { type: fileType });
      formData.append('file', blob, 'img.png'); 
      formData.append('filePath', filePath);
      var url = `${S3_URL()}uploadfile`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      try{
        const response = await axiosInstance.post(url, formData, headers);
        if(response.status == 200){
          logo_file = `${S3_URL()}s3/${filePath}`
          mainCanvas.set({
            logo_file:logo_file, 
          })
        }
      }
      catch(e){
      }
    }


    var header_banner = cropHeaderPreviewData;
    if(cropHeaderBannerData) {
      var filename = "logo.png"
        // const reader = new FileReader();
      var data = cropHeaderBannerData.slice(0, cropHeaderBannerData.size);
      var filePath = `map_log/${uuidv4()}`
      const formData = new FormData();
      const fileType = cropHeaderBannerData.type || "image/png"; 
      const blob = cropHeaderBannerData.type ? cropHeaderBannerData : new Blob([cropHeaderBannerData], { type: fileType });
      formData.append('file', blob, 'img.png'); 
      formData.append('filePath', filePath);
      var url = `${S3_URL()}uploadfile`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      try{
        const response = await axiosInstance.post(url, formData, headers);
        if(response.status == 200){
          header_banner = `${S3_URL()}s3/${filePath}`
          mainCanvas.set({
            header_banner:header_banner, 
          })
        }
      }
      catch(e){
      }
    }
    var mapWidthFeet = mapWidth;
    var mapHeightFeet = mapHeight; 
    var mapOffsetXFeet = mapOffsetX; 
    var mapOffsetYFeet = mapOffsetY;
    
    
    var convertRateX= Math.abs(mainCanvas.convertRateX)
    var mapWidthPx = unit2Px(mapWidthFeet, convertRateX, mainCanvas.mapUnit)
    var mapHeightPx = unit2Px(mapHeightFeet, convertRateX, mainCanvas.mapUnit)
    var diffMapOffsetX = mapOffsetXFeet - (mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0) 
    var diffMapOffsetY = mapOffsetYFeet - (mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0) 
    mainCanvas.forEachObject((object) => {
        var layer = object.get('layer'); 
        if(object.get('class') != 'line' && object.get('lockforever') != true){                
            object.left = object.left+unit2Px(diffMapOffsetX, convertRateX, mainCanvas.mapUnit);            
            object.top = object.top+unit2Px(diffMapOffsetY, convertRateX, mainCanvas.mapUnit);
        }
    });
    var headerColor = convertRgbStringHex(headerColorRGB)
    mainCanvas.set({
      mapWidth:mapWidthFeet, 
      mapHeight:mapHeightFeet,
      endX:mapWidthPx,
      endY:mapHeightPx,
      startX:0,
      startY:0,
      mapUnit:mapUnit,
      gridSize:gridSize,
      showGrid:showGrid,
      snapToGrid: snapToGrid,
      mapOffsetX: mapOffsetXFeet,
      mapOffsetY: mapOffsetYFeet,
      gridAngle: gridAngle,
      contract_pdf: contract_pdf,
      sales_form: sales_form,
      event_home: event_home,
      header_color: headerColor
    })
    var bgImgCanvas = props.main.bgImgCanvas
    if(bgImgCanvas.backgroundImage){
      bgImgCanvas.backgroundImage.set({
        left: bgImgCanvas.backgroundImage.left+unit2Px(diffMapOffsetX, convertRateX, mainCanvas.mapUnit),
        top: bgImgCanvas.backgroundImage.top+unit2Px(diffMapOffsetY, convertRateX, mainCanvas.mapUnit),
      })
    }    
    var bgCanvas = props.main.bgCanvas
    if(bgCanvas.backgroundImage){
      bgCanvas.backgroundImage.set({
        left: bgCanvas.backgroundImage.left+unit2Px(diffMapOffsetX, convertRateX, mainCanvas.mapUnit),
        top: bgCanvas.backgroundImage.top+unit2Px(diffMapOffsetY, convertRateX, mainCanvas.mapUnit),
      })
    }
    var activeFile = {...props.main.active_booth_file,
      mapWidth:mapWidthFeet, 
      mapHeight:mapHeightFeet,
      endX:mapWidthPx, 
      endY:mapHeightPx, 
      mapUnit:mapUnit, 
      gridSize:gridSize, 
      showGrid:showGrid, 
      snapToGrid: snapToGrid, 
      mapOffsetX: mapOffsetXFeet, 
      mapOffsetY: mapOffsetYFeet, 
      gridAngle: gridAngle,
      sales_form: sales_form,
      event_home: event_home,
      header_color: headerColor
    }

                
    props.setActiveBoothFile(activeFile)
    
    mainCanvas.clipPath = new fabric.Rect({
      width: mapWidthPx+5,
      height: mapHeightPx+5,
      top: 45,
      left: 45
    });
    
    props.main.bgCanvas.clipPath = new fabric.Rect({
      width: mapWidthPx+5,
      height: mapHeightPx+5,
      top: 45,
      left: 45
    });

    props.main.g_grid.clipPath = new fabric.Rect({
      width: mapWidthPx+5,
      height: mapHeightPx+5,
      top: 45,
      left: 45
    });
    
    props.main.bgImgCanvas.clipPath = new fabric.Rect({
      width: mapWidthPx+5,
      height: mapHeightPx+5,
      top: 45,
      left: 45
    });

    mainCanvas.renderAll();
    props.main.bgCanvas.renderAll();
    props.main.bgImgCanvas.renderAll();
    redrawGrid(props.main.g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
    props.redrawRulers();
    renderBorder(mainCanvas);
    console.log("mainCanvas", mainCanvas)
    var settingData = {}
    var showSettingData = {}
    if(first_mapWidth != mapWidthFeet){
      if(!settingData.CANVAS){
        settingData.CANVAS = {}
        settingData.CANVAS.MAP_KEY = activeFile.KEY_ID
      }
      settingData.CANVAS.SIZE_X = mapWidthFeet?mapWidthFeet:0
    }
    if(first_mapHeight != mapHeightFeet){
      if(!settingData.CANVAS){
        settingData.CANVAS = {}
        settingData.CANVAS.MAP_KEY = activeFile.KEY_ID
      }
      settingData.CANVAS.SIZE_Y = mapHeightFeet?mapHeightFeet:0
    }
    if(first_gridSize != gridSize){
      if(!settingData.CANVAS){
        settingData.CANVAS = {}
        settingData.CANVAS.MAP_KEY = activeFile.KEY_ID
      }
      settingData.CANVAS.GRID_SIZE = gridSize?gridSize:0
    }
    if(first_showGrid != showGrid){
      if(!settingData.CANVAS){
        settingData.CANVAS = {}
        settingData.CANVAS.MAP_KEY = activeFile.KEY_ID
      }
      settingData.CANVAS.GRID_ON = showGrid?1:0
    }
    if(first_snapToGrid != snapToGrid){
      if(!settingData.CANVAS){
        settingData.CANVAS = {}
        settingData.CANVAS.MAP_KEY = activeFile.KEY_ID
      }
      settingData.CANVAS.GRID_SNAP = snapToGrid?1:0
    }
    if(first_gridAngle != gridAngle){
      if(!settingData.CANVAS){
        settingData.CANVAS = {}
        settingData.CANVAS.MAP_KEY = activeFile.KEY_ID
      }
      settingData.CANVAS.GRID_ANGLE = gridAngle?gridAngle:0
    }
    if(first_mapOffsetX != mapOffsetXFeet){
      if(!settingData.MAP_OFFSET){
        settingData.MAP_OFFSET = {}
        settingData.MAP_OFFSET.MAP_KEY = activeFile.KEY_ID
      }
      settingData.MAP_OFFSET.OFFSET_X = mapOffsetXFeet?mapOffsetXFeet:0
    }
    if(first_mapOffsetY != mapOffsetYFeet){
      if(!settingData.MAP_OFFSET){
        settingData.MAP_OFFSET = {}
        settingData.MAP_OFFSET.MAP_KEY = activeFile.KEY_ID
      }
      settingData.MAP_OFFSET.OFFSET_Y = mapOffsetYFeet?mapOffsetYFeet:0
    }
    if(first_mapUnit != mapUnit){
      if(!settingData.GENERAL){
        settingData.GENERAL = {}
        settingData.GENERAL.MAP_KEY = activeFile.KEY_ID
      }
      settingData.GENERAL.UNIT = mapUnit?mapUnit:""
    }
    if(first_event_home != event_home){
      if(!settingData.GENERAL){
        settingData.GENERAL = {}
        settingData.GENERAL.MAP_KEY = activeFile.KEY_ID
      }
      settingData.GENERAL.HOME_LINK = event_home?event_home:""
    }
    if(first_logo != logo_file){
      if(!showSettingData.PUBLIC){
        showSettingData.PUBLIC = {}
      }
      showSettingData.PUBLIC.LOGO = logo_file?logo_file:""

    }
    
    if(first_header_banner != header_banner){
      if(!showSettingData.PUBLIC){
        showSettingData.PUBLIC = {}
      }
      showSettingData.PUBLIC.HEADER_BANNER = header_banner?header_banner:""
    }
    if(first_header_color != headerColor && headerColor){
      if(!showSettingData.PUBLIC){
        showSettingData.PUBLIC = {}
      }
      showSettingData.PUBLIC.HEADER_COLOR = headerColor?headerColor:""
    }
    
    if(first_sales_form != sales_form){
      if(!settingData.GENERAL){
        settingData.GENERAL = {}
        settingData.GENERAL.MAP_KEY = activeFile.KEY_ID
      }
      settingData.GENERAL.FORM_KEY = sales_form?sales_form:""
    }
    console.log(settingData)

    // return;
    props.onOk(settingData, showSettingData)
  }

  const resetGrid = (e)=>{
    
    mainCanvas.set({
      gridOffsetX: mainCanvas.gridOffsetXDefault?mainCanvas.gridOffsetXDefault:0,
      gridOffsetY: mainCanvas.gridOffsetYDefault?mainCanvas.gridOffsetYDefault:0,
    })
    mainCanvas.renderAll();
    redrawGrid(props.main.g_grid, mainCanvas, (mainCanvas.showGrid && mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
  }

  const deleteMap = (map_name, key_id)=>{
    // setDeleteId(key_id)
    deleteId = key_id
    setDeleteTitle(`Are you sure you want to delete the map ${map_name}?`)
    setDeleteMapTitle(map_name)
    handleCloseDeleteModal = handleCloseDeleteMapModal
    confirmDeleteModal = confirmDeleteMapModal
    setOpenDeleteModal(true)
  }

  const deleteBoothGroup = (editGroupItem)=>{
    // setDeleteId(editGroupItem.KEY_ID)
    deleteId = editGroupItem.KEY_ID
    setDeleteTitle(`Are you sure you want to delete the group?`)
    handleCloseDeleteModal = handleCloseDeleteGroupModal
    confirmDeleteModal = confirmDeleteGroupModal
    setOpenDeleteModal(true)
  }

  const migrateMap1 = ()=>{
    var v3KEY_ID = false//'82303810-AD81-DED6-948A-6DCAC78EE6FF';
    // var active_booth_file = props.main.active_booth_file
    // if(active_booth_file){
    //   fileId = active_booth_file._id
    // }
    var url = `/migrateMap?_id=${eshowMapId}`
    var formdata = {
      v3KEY_ID
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.post(url, data, options).then(res=>{
        alert("migrated successfully")
        window.location.reload()
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const migrateMap = ()=>{
    props.firstMigrate()
    setDisableMigrate(true)
  }

  
  const confirmDeleteMapModal=()=>{
    if(deleteId){
      var url = `${ESHOW_URL()}?method=deleteMap&&map_key=${deleteId}&token=${getTokenCookie()}` 
      
      var formdata = 
      {  
         query:`${ESHOW_URL()}?method=deleteMap&&map_key=${deleteId}&token=${getTokenCookie()}`                
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      })
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      props.setLoading(true)
      axiosInstance.get(url).then(async res=>{
          const result = res.data;
          if(result.SUCCESS == true){
              setOpenDeleteModal(false)
              props.setLoading(false)
              // setDeleteId(false)

              var newLogs = [];
              var logKey = uuidv4()
              newLogs.push({
                  BOOTH_KEY: deleteId,
                  KEY_ID: logKey,
                  ACTIVE: 1,
                  TYPE: 2012,
                  LOG_TEXT: {
                      BOOTH_KEY:deleteId,
                      KEY_ID: logKey,
                      action:"delete_dwg",
                      date: new Date().toLocaleString(),
                      description: "deleted map "+(deleteMapTitle?deleteMapTitle:""),
                      user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                  },
                  USER_NAME: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
              })
              await saveLog(newLogs);
              deleteId = false
              
              window.location.reload()
          }
          else{
              setOpenSnack(true);
              setSnackMsg("Failed to delete");
              props.setLoading(false)
          }
          
      }).catch(error => {
          console.log("error", error);
      })
    }    
  }

  const handleCloseDeleteMapModal=()=>{
    setOpenDeleteModal(false)
  }

  const seDefaultGrid = (e)=>{
    
    mainCanvas.set({
      gridOffsetXDefault: mainCanvas.gridOffsetX?mainCanvas.gridOffsetX:0,
      gridOffsetYDefault: mainCanvas.gridOffsetY?mainCanvas.gridOffsetY:0,
    })
    mainCanvas.renderAll();
    setSnackMsg('Defalt Grid Alignment has been setup.')
    setOpenSnack(true)
  }

  const handleShowGrid = (value)=>{
    setShowGrid(value)
    setTimeout(function(){
      if(value){
        redrawGrid(props.main.g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
        if(gridAngle)
          rotateCanvasAndElements(mainCanvas, gridAngle);
      }
      else{
        removeGrid(props.main.mainCanvas)
      }
    }, 100)

  }

  
  const handleOpenAdd = ()=>{
    // if(!openAdd){
      setEditGroupItem({
        KEY_ID:"", 
        ACTIVE:1, 
        TITLE:'', 
        SOLD:`${0xc2},${0x18},${0x5b}`, 
        SOLD_RGB:convertRgb(`${0xc2},${0x18},${0x5b}`), 
        PENDING:`${0xff},${0xeb},${0x3b}`, 
        PENDING_RGB:convertRgb(`${0xff},${0xeb},${0x3b}`), 
        AVAILABLE:`${0x4d},${0xd0},${0xe1}`, 
        AVAILABLE_RGB:convertRgb(`${0x4d},${0xd0},${0xe1}`), 
        RESERVED:`${0xff},${0x6f},${0x00}`, 
        RESERVED_RGB:convertRgb(`${0xff},${0x6f},${0x00}`), 
        FONT_COLOR_RGB:convertRgb(`${0x00},${0x00},${0x00}`), 
        DISPLAY_ORDER: groupOptions.length
      });
    // }
    setOpenAdd(true)
    setOpenEdit(false)
  }

  const handleOpenEditGroup = (row)=>{
    var tmp = {...row}
    if(tmp.SOLD && tmp.SOLD !=''){
      tmp.SOLD_RGB = convertRgb(tmp.SOLD)
    }
    else{
      tmp.SOLD_RGB = convertRgb(`${0xc2},${0x18},${0x5b}`)
    }
    if(tmp.PENDING && tmp.PENDING !=''){
      tmp.PENDING_RGB = convertRgb(tmp.PENDING)
    }
    else{
      tmp.PENDING_RGB = convertRgb(`${0xff},${0xeb},${0x3b}`)
    }
    if(tmp.AVAILABLE && tmp.AVAILABLE !=''){
      tmp.AVAILABLE_RGB = convertRgb(tmp.AVAILABLE)
    }
    else{
      tmp.AVAILABLE_RGB = convertRgb(`${0x4d},${0xd0},${0xe1}`)
    }
    if(tmp.RESERVED && tmp.RESERVED !=''){
      tmp.RESERVED_RGB = convertRgb(tmp.RESERVED)
    }
    else{
      tmp.RESERVED_RGB = convertRgb(`${0xff},${0x6f},${0x00}`)
    }
    if(tmp.FONT_COLOR && tmp.FONT_COLOR !=''){
      tmp.FONT_COLOR_RGB = convertRgb(tmp.FONT_COLOR)
    }
    else{      
      tmp.FONT_COLOR_RGB = convertRgb(`${0x00},${0x00},${0x00}`)
    }
    
    setEditGroupItem(tmp);    
    setOpenAdd(false)
    setOpenEdit(true)
  }

  const handleEditItemValue = (key, value)=>{
    setEditGroupItem({...editGroupItem, [key]:value});
  }

  const handleOpenAddPriceSaleItem = ()=>{
    // if(!openAddPriceSaleItem){
      setEditPriceSaleItem({
        name:'', description:'', price:0, price_type:0, member:false, nonmember: false, nonprofit:false, start_date:'', end_date:'', promo_code:'', booth_group:'', order: props.main.pricingOptions.length
      });
    // }
    setOpenAddPriceSaleItem(true)
    setOpenEditPriceSaleItem(false)
  }

  const handleOpenEditPriceSaleItem = (row)=>{
    setEditPriceSaleItem(row);    
    setOpenAddPriceSaleItem(false)
    setOpenEditPriceSaleItem(true)
  }

  const handleEditPriceSaleItemValue = (key, value)=>{
    setEditPriceSaleItem({...editPriceSaleItem, [key]:value});
  }

  const handleOpenAddPremiumSaleItem = ()=>{
    // if(!openAddPremiumSaleItem){
      setEditPremiumSaleItem({
        name:'', description:'', price:0, price_type:0, member:false, nonmember: false, nonprofit:false, start_date:'', end_date:'', promo_code:'', booth_group:'', order: props.main.pricingOptions.length
      });
    // }
    setOpenAddPremiumSaleItem(true)
    setOpenEditPremiumSaleItem(false)
  }

  const handleOpenEditPremiumSaleItem = (row)=>{
    setEditPremiumSaleItem(row);    
    setOpenAddPremiumSaleItem(false)
    setOpenEditPremiumSaleItem(true)
  }

  const handleEditPremiumSaleItemValue = (key, value)=>{
    setEditPremiumSaleItem({...editPremiumSaleItem, [key]:value});
  }

  const handleOpenAddDiscountItem = ()=>{
    // if(!openAddPremiumSaleItem){
      setEditDiscountItem({
        name:'', description:'', price:0, price_type:0, member:false, nonmember: false, nonprofit:false, start_date:'', end_date:'', promo_code:'', booth_group:'', order: props.main.pricingOptions.length
      });
    // }
    setOpenAddDiscountItem(true)
    setOpenEditDiscountItem(false)
  }

  const handleOpenEditDiscountItem = (row)=>{
    setEditDiscountItem(row);    
    setOpenAddDiscountItem(false)
    setOpenEditDiscountItem(true)
  }

  const handleOpenEditMapItem = (row)=>{
    setEditMapItem(row);    
    setOpenEditMapItem(true)
    fetchEditMapSetup(row.KEY_ID)
  }
  
  
  const handleEditMapItemValue = (key, value)=>{
    setEditMapItem({...editMapItem, [key]:value});
  }

  const handleEditDiscountItemValue = (key, value)=>{
    setEditDiscountItem({...editDiscountItem, [key]:value});
  }

  const handleOpenAddCategoryItem = ()=>{
    // if(!openAddPremiumSaleItem){
      console.log("parentCategoryItem", parentCategoryItem)
      var LEVEL1 = ''
      var LEVEL2 = ''
      if(!parentCategoryItem || !parentCategoryItem?.KEY_ID) {
        var mainCategoryLevels = [];
        if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
          
          var searched = false
          for (var j = 65; j <= 90; j++) {
            for (var k = 65; k <= 90; k++) {
              var letters = `${String.fromCharCode(j)}${String.fromCharCode(k)}`
              searched = false
              for(var i = 0; i <props.main.categoryOptions.length; i++ ){
                if(letters == props.main.categoryOptions[i]['LEVEL1']){
                  searched = true;
                  break;
                }
              }
              if(searched) {
                continue
              }
              else{
                LEVEL1 = letters
                break;
              }
            }
            if(!searched) break;
          }
        }
        LEVEL2 = '0000'
      }
      else{
        if(parentCategoryItem.LEVEL2 != '0000'){
          alert("You can not create more subcategory!")
          return
        }
        var subCategoryLevels = [];
        LEVEL1 = parentCategoryItem.LEVEL1;
        LEVEL2 = ''
        var subCategories = parentCategoryItem.SUBCAT?parentCategoryItem.SUBCAT:[]
        if(subCategories.length == 0){
          LEVEL2 = '0001'
        }
        else{
          for(var i = 0; i <subCategories.length; i++ ){
            subCategoryLevels.push(subCategories[i]['LEVEL2'])
          }
          console.log("subCategoryLevels", subCategoryLevels)
          var highestLetter = subCategoryLevels.sort().pop();
          console.log("highestLetter", highestLetter)
          LEVEL2 = highestLetter*1+1
          LEVEL2 = `${parseInt(LEVEL2/1000)%10}${parseInt(LEVEL2/100)%10}${parseInt(LEVEL2/10)%10}${parseInt(LEVEL2/1)%10}`
        }
      }
      if(LEVEL1 == '' || LEVEL2 == ''){
        alert("Cannot create more category")
        return;
      }
      var newItem = {
        ACTIVE: 1,
        KEY_ID:"",
        LEVEL1:LEVEL1,
        LEVEL2:LEVEL2,
        ID:`${LEVEL1}${LEVEL2}`,
        TITLE: ""
      }
      console.log("newItem", newItem)
      setEditCategoryItem(newItem);
    // }
    setOpenAddCategoryItem(true)
    setOpenEditCategoryItem(false)
  }


  const handleOpenEditCategoryItem = (row)=>{
    setEditCategoryItem(row);  
    setParentCategoryItem(row)  
    setOpenAddCategoryItem(false)
    setOpenEditCategoryItem(true)
  }

  const handleEditCategoryItemValue = (key, value)=>{
    setEditCategoryItem({...editCategoryItem, [key]:value});
  }


  const onSelectLogo = (e)=> {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        setOpenCropModal(true)
        setSelectedTreeCropItem('logo')
        e.target.value=null;
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onSelectHeaderBanner = (e)=> {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        setOpenCropModal(true)
        setSelectedTreeCropItem('headerbanner')
        e.target.value=null;
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onSelectContract = async (e)=>{
      var file = e.target.files[0]   
      var filename = file.name
        // const reader = new FileReader();
      var data = file.slice(0, file.size);
      var url = API_URL()+`uploadregularfile?ax-file-path=/content/contract/&ax-file-name=${filename}&&start=0`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      const response = await axiosInstance.post(url, data, headers);
      if(response.status == 200){
        setContracPdfName(filename);
      }
      props.setLoading(false)
  }

  const confirmCropModal=(dataImg, dataUrl)=>{
    if(selectedCropItem == "logo"){
      setCropLogoData(dataImg)
      setCropLogoPreviewData(dataUrl)
    }
    else if(selectedCropItem == "headerbanner"){
      setCropHeaderBannerData(dataImg)
      setCropHeaderPreviewData(dataUrl)      
    }
    setOpenCropModal(false)
  }

  const handleCloseCropModal=()=>{
    setOpenCropModal(false)
  }



  function getChildById(node, KEY_ID) {
    let array = [];

    //returns an array of nodes ids: clicked node id and all children node ids
    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes.KEY_ID);
      // if (Array.isArray(nodes.children)) {
      //   nodes.children.forEach((node) => {
      //     array = [...array, ...getAllChild(node)];
      //     array = array.filter((v, i) => array.indexOf(v) === i);
      //   });
      // }
      return array;
    }

    //returns the node object that was selected
    function getNodeById(nodes, KEY_ID) {
      if (nodes.KEY_ID === KEY_ID) {
        return nodes;
      } else if (Array.isArray(nodes.SUBCAT)) {
        let result = null;
        nodes.SUBCAT.forEach((node) => {
          if (!!getNodeById(node, KEY_ID)) {
            result = getNodeById(node, KEY_ID);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, KEY_ID));
  }

  function getOnChange(checked, nodes) {  
    var checked = (selectedTree === nodes.KEY_ID?false:nodes.KEY_ID)
    setSelectedTree(checked);
    if(checked){
      var searchItem = nodes//getChildById(nodes, nodes.KEY_ID)
      if(searchItem){
        handleOpenEditCategoryItem(searchItem)
      }
      // for(var i = 0; i < props.main.categoryOptions.length; i++){
      //   if(props.main.categoryOptions[i].KEY_ID === nodes.KEY_ID){
      //     handleOpenEditCategoryItem(props.main.categoryOptions[i])
      //     break;
      //   }
      // }
    }
    else{
      setOpenAddCategoryItem(false); setOpenEditCategoryItem(false)
      setParentCategoryItem({})
    }
  }

  const RenderTreeWithCheckboxes = (nodes) => {
    return (
      <TreeItem
        key={nodes.KEY_ID}
        nodeId={nodes.KEY_ID}
        label={
          // <FormLabel>{<>{nodes.name}</>}</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedTree === nodes.KEY_ID}
                onChange={(event) =>
                  getOnChange(event.currentTarget.checked, nodes)
                }
                //onClick={(e) => e.stopPropagation()}
              />
            }
            label={<>{nodes.TITLE}</>}
            key={nodes.KEY_ID}
          />
        }
      >
        {Array.isArray(nodes.SUBCAT)
          ? nodes.SUBCAT.map((node) => RenderTreeWithCheckboxes(node))
          : null}
      </TreeItem>
    );
  };

  if(props.main.groupOptions)
  props.main.groupOptions.sort(function (a, b) {
      return (a.order?a.order:0)*1 - (b.order?b.order:0)*1;
    });
  var categoryData = {
    KEY_ID: "0",
    TITLE: "Parent",
    SUBCAT: []
  }
  if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
    // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
    categoryData.SUBCAT = (props.main.categoryOptions)
  }
  else{

  }
  var boothFileList = [...props.main.boothFileList]
  // boothFileList.sort(
  //   function(a, b) {          
  //     //  if ((a.order?a.order:0)*1 ===(b.order?b.order:0)*1) {
  //     //     // Price is only important when cities are the same
  //     //     return a.updatedAt > b.updatedAt ? 1 : -1;
  //     //  }
  //     return a.filename > b.filename
  //      return (a.order?a.order:0)*1 - (b.order?b.order:0)*1 ? 1 : -1;
  // });
  boothFileList.sort(function (a, b) {
    let fa = (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1,
    fb = (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    // fa = (a.updatedAt?a.updatedAt:0)
    // fb = (b.updatedAt?b.updatedAt:0)
    // if (fa > fb) {
    //   return -1;
    // }
    // if (fa < fb) {
    //     return 1;
    // }
    return 0;
  });

  var migrateValid = true;
  
  var eshowMapId = "63cea991e5cf5a11aed65881"
  if(boothFileList){
    for(var i = 0; i < boothFileList.length; i++){
      var boothFile = boothFileList[i]
      var mapJS = boothFile.MAPJS;
      var content
      try{        
        mapJS = mapJS.replaceAll('\r\n', ' ')
        mapJS = JSON.parse(mapJS)
        if(mapJS._id == eshowMapId){
          migrateValid = false; break;
        }
      }catch (e) {
        console.log(e)
      }
    }
  }
  console.log("boothFileList", boothFileList)
  var mechantAccount = props.main.mechantAccount
  var migrated = false
  var active_booth_file = props.main.active_booth_file
  if(active_booth_file){
    migrated = !(active_booth_file.MIGRATE === true)
  }
  console.log("editMapSetup", editMapSetup)
  return (
    
 
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'setup-dialog-title'}}
      aria-labelledby="setup-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
      open={open}>
      <DialogTitle id='setup-dialog-title' style={{display:'flex', alignItems:'center'}}><SettingsIcon style={{marginRight: 10}}/>Setup</DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={<b>General</b>} {...a11yProps(0)} />
              <Tab label={<b>Grid</b>} {...a11yProps(1)} />
              <Tab label={<b>Groups</b>} {...a11yProps(2)} />
              <Tab label={<b>Prices</b>} {...a11yProps(3)} />
              <Tab label={<b>Premiums</b>} {...a11yProps(4)} />
              <Tab label={<b>Discounts</b>} {...a11yProps(5)} />
              <Tab label={<b>Categories</b>} {...a11yProps(6)} />
              <Tab label={<b>Maps</b>} {...a11yProps(7)} />
              {/* <Tab label={<b>Merchant</b>} {...a11yProps(8)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel>Map Unit</FormLabel>
                  <RadioGroup row
                    aria-labelledby=""
                    value={mapUnit}
                    onChange={(e)=>onChangeUnit(e.target.value)}
                  >
                    <FormControlLabel value="feet" control={<Radio />} label="Feet (ft)" />
                    <FormControlLabel value="meter" control={<Radio />} label="Meter (m)" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                  <FormLabel component="legend"><b>Canvas Size</b></FormLabel>
                  <Box mt={2}>
                    <TextField                
                      name="filename"
                      margin="dense"
                      label="Width"
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value = {mapWidth}
                      onChange={(e)=>setMapWidth(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      style={{marginTop:20}}
                      name="filename"
                      margin="dense"
                      label="Height"
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value = {mapHeight}
                      onChange={(e)=>setMapHeight(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
              </Grid>
              <Grid item xs={6}>
                  <FormLabel component="legend"><b>Links</b></FormLabel>
                  <Box mt={2}>
                    <TextField                
                      name="event_home"
                      margin="dense"
                      label="Event Home"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={event_home}
                      onChange={(e)=>setevent_home(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
              </Grid>
              <Grid item xs={6}>
                    <TextField          
                      style={{marginTop:20}}      
                      name="filename"
                      margin="dense"
                      label="Offset X"
                      type="number"
                      fullWidth
                      variant="outlined"  
                      size="small"
                      value = {mapOffsetX}
                      onChange={(e)=>setMapOffsetX(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
              </Grid>
              <Grid item xs={6}>
                    <TextField
                      style={{marginTop:20}}
                      name="filename"
                      margin="dense"
                      label="Offset Y"
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value = {mapOffsetY}
                      onChange={(e)=>setMapOffsetY(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
              </Grid>
              <Grid item xs={6}>
                <Box mt={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel shrink={true} >Sales Form</InputLabel>
                    <Select
                      input={<OutlinedInput notched label="Sales Form" />}
                      fullWidth
                      size="small"
                      value={sales_form}
                      onChange={(e)=>{setSalesForm(e.target.value)}}
                    >
                      {
                        saleforms?.map((item, index)=>{
                          return(
                            <MenuItem key={index} value={item.KEY_ID}>{item.TITLE}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mb={2}><label><b>Logo</b></label></Box>  
                <FormControl variant="outlined">
                  <input type="file" accept="image/*" onChange={onSelectLogo} />
                </FormControl>
                    
                {cropLogoPreviewData&&
                <div style={{display:'flex'}}>
                  <div className='preview-image' style={{height:'auto'}}>
                    <img style={{ width: "100%" }} src={cropLogoPreviewData} alt="cropped" />
                  </div>
                  <IconButton  size="small" style={{marginLeft:10}}
                      onClick={()=>{
                        setCropLogoData(null)
                        setCropLogoPreviewData(null)
                      }}
                  
                    ><DeleteForeverOutlinedIcon/>
                  </IconButton>
                </div>
                }
              </Grid>
              {/* <Grid item xs={6}>
                <Box mb={2}><label><b>Contract Template</b></label></Box>  
                <FormControl variant="outlined">
                  <input type="file" accept="pdf" onChange={onSelectContract} />
                </FormControl>
                {contract_pdf &&
                <p>{contract_pdf}</p>   
                }
              </Grid>
              */}
              
              <Grid item xs={6} >     
              
                <Box mb={2}><label><b>Header Banner</b></label></Box>  
                <FormControl variant="outlined">
                  <input type="file" accept="image/*" onChange={onSelectHeaderBanner} />
                </FormControl>
                    
                {cropHeaderPreviewData&&
                <div style={{display:'flex'}}>
                  <div className='preview-image' style={{height:'auto'}}>
                    <img style={{ width: "100%" }} src={cropHeaderPreviewData} alt="cropped" />
                  </div>
                  <IconButton  size="small" style={{marginLeft:10}}
                      onClick={()=>{
                        setCropHeaderPreviewData(null)
                        setCropHeaderBannerData(null)
                      }}
                  
                    ><DeleteForeverOutlinedIcon/>
                  </IconButton>
                </div>
                }   
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField          
                        label="Header Color"      
                        name="SOLD"
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        size="small"
                        // value={convertRgb(headerColor)}
                        // onChange={(e)=>setHeaderColor(e.target.value)}
                        value={headerColorRGB}
                        onChange={(e)=>setHeaderColorRGB(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} className = "item-color">
                      <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('header', headerColorRGB)}>
                        <div className="color-icon" style={{backgroundColor:(headerColor&&headerColor!=""?(headerColorRGB):'rgba(224, 224, 224, 1)')}}>
                        </div>
                        <ModeEditOutlineOutlinedIcon/> 
                      </div>                         
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6} >
                  {/* <Button disabled={!migrateValid&0} sx={{ marginTop: '15px', marginRight: '10px' }} variant="contained" onClick={migrateMap}>First Time Migration</Button> */}
                  <Button disabled={migrated} sx={{ marginTop: '15px', marginRight: '10px' }} variant="contained" onClick={migrateMap}>First Time Migration</Button>
                  
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Box mt={2}>
                    <TextField                
                      name="filename"
                      margin="dense"
                      label="Grid Size"
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={gridSize}
                      onChange={(e)=>{
                        setGridSize(e.target.value)
                        mainCanvas.set({
                          gridSize:e.target.value
                        })
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
              </Grid>
              <Grid item xs={6}>
                  <Box mt={2}>
                    <TextField                
                      name="filename"
                      margin="dense"
                      label="Grid Angle"
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={(e)=>setGridAngle(e.target.value)}
                      value={gridAngle}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
              </Grid>
              <Grid item xs={6}>
                  <Box mt={2}>
                    <FormControlLabel
                      value="start"
                      control={<Switch color="primary" checked={showGrid} 
                      onChange={(event)=>{                                           
                        mainCanvas.set({
                          showGrid:event.target.checked
                        })
                        mainCanvas.renderAll()
                        handleShowGrid(event.target.checked)    
                      }}/>}
                      label="Show Grid"
                      labelPlacement="start"
                    />
                  </Box>
              </Grid>
              <Grid item xs={6}>
                  <Box mt={2}>
                    <FormControlLabel
                      value="start"
                      control={<Switch color="primary" disabled={!showGrid} checked={snapToGrid} 
                      onChange={(event)=>{
                        mainCanvas.set({
                          snapToGrid:event.target.checked
                        })
                        setSnapToGrid(event.target.checked)
                      }}/>}
                      label="Snap to Grid"
                      labelPlacement="start"
                    />
                  </Box>
              </Grid>
              
              <Grid item xs={12}>
                  <Box mt={2}>
                    <Button  sx={{ marginRight: 15}} variant="contained" onClick={resetGrid}>Reset Grid Alignment</Button>
                    <Button variant="contained" onClick={seDefaultGrid}>Default Grid Alignment</Button>
                  </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={2}>
              {/* <label style={{fontSize:14.5}}>Booth Number:{props.boothTexts[0]}</label>*/}
              
              <Table className="color-table" sx={{ minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="25%"></TableCell>
                    <TableCell colSpan={2} width="20%" style={{textAlign:'center'}}>Exhibitor</TableCell>
                    <TableCell colSpan={3} width="30%" style={{textAlign:'center'}}>Booth</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="25%">Title</TableCell>
                    <TableCell width="10%">Sold</TableCell>
                    <TableCell width="10%">Pending</TableCell>
                    <TableCell width="10%">Available</TableCell>
                    <TableCell width="10%">Hold</TableCell>
                    <TableCell width="10%">Font</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.main.groupOptions.map((row, index) => {
                    return (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <a href="javascript:void(0)" onClick={()=>handleOpenEditGroup(row)}>{row.TITLE}</a>
                      </TableCell>
                      <TableCell style={{backgroundColor: convertRgb(row.SOLD)}}></TableCell>
                      <TableCell style={{backgroundColor: convertRgb(row.PENDING)}}></TableCell>
                      <TableCell style={{backgroundColor: convertRgb(row.AVAILABLE)}}></TableCell>
                      <TableCell style={{backgroundColor: convertRgb(row.RESERVED)}}></TableCell>
                      <TableCell style={{backgroundColor: convertRgb(row.FONT_COLOR)}}></TableCell>
                    </TableRow>                  
                  )})
                }
                </TableBody>
              </Table>
              <Box mt={2}>
                <Button variant="text" onClick={()=>{handleOpenAdd()}}  startIcon={<AddIcon />}><b>Create New Booth Group</b></Button>
                <Collapse in={openAdd || openEdit}>
                  {openAdd&&
                  <h4>New Booth Group</h4>
                  }
                  {openEdit&&
                  <h4>Edit Booth Group ({editGroupItem.TITLE})</h4>
                  }
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div className='subtitle'>
                      Booth Group Edit
                      </div>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Group Name
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <TextField                
                        name="name"
                        margin="dense"
                        
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editGroupItem.TITLE}
                        onChange={(e)=>handleEditItemValue('TITLE', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Display Order
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <TextField                
                        name="order"
                        margin="dense"
                        type="number"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editGroupItem.DISPLAY_ORDER?editGroupItem.DISPLAY_ORDER:0}
                        onChange={(e)=>handleEditItemValue('DISPLAY_ORDER', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className='subtitle'>
                        Exhibitor Booth Map Colors (RGB colors)
                      </div>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Sold
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="SOLD"
                            margin="dense"
                            // disabled = {true}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={(editGroupItem.SOLD_RGB)}
                            onChange={(e)=>handleEditItemValue('SOLD_RGB', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('SOLD', editGroupItem.SOLD_RGB)}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.SOLD_RGB&&editGroupItem.SOLD_RGB!=""?(editGroupItem.SOLD_RGB):'rgba(224, 224, 224, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={4} className="item-label">
                      Pending
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="PENDING"
                            margin="dense"
                            // disabled={true}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={(editGroupItem.PENDING_RGB)}
                            onChange={(e)=>handleEditItemValue('PENDING_RGB', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('PENDING', editGroupItem.PENDING_RGB)}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.PENDING_RGB&&editGroupItem.PENDING_RGB!=""?(editGroupItem.PENDING_RGB):'rgba(224, 224, 224, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <div className='subtitle'>
                        Booth Map Colors (RGB colors)
                      </div>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Available
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="available"
                            margin="dense"
                            // disabled={true}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={(editGroupItem.AVAILABLE_RGB)}
                            onChange={(e)=>handleEditItemValue('AVAILABLE_RGB', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('AVAILABLE', editGroupItem.AVAILABLE_RGB)}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.AVAILABLE_RGB&&editGroupItem.AVAILABLE_RGB!=""?(editGroupItem.AVAILABLE_RGB):'rgba(224, 224, 224, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Reserved
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="reserved"
                            margin="dense"
                            // disabled={true}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={(editGroupItem.RESERVED_RGB)}
                            onChange={(e)=>handleEditItemValue('RESERVED_RGB', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('RESERVED', editGroupItem.RESERVED_RGB)}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.RESERVED_RGB&&editGroupItem.RESERVED_RGB!=""?(editGroupItem.RESERVED_RGB):'rgba(224, 224, 224, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Font Color
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="font_color"
                            margin="dense"
                            // disabled={true}
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={(editGroupItem.FONT_COLOR_RGB)}
                            onChange={(e)=>handleEditItemValue('FONT_COLOR_RGB', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('FONT_COLOR', editGroupItem.FONT_COLOR_RGB)}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.FONT_COLOR_RGB&&editGroupItem.FONT_COLOR_RGB!=""?(editGroupItem.FONT_COLOR_RGB):'rgba(0, 0, 0, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                      <Button variant="contained" onClick={saveBoothGroup} style={{marginRight: 10}}>Save</Button>                      
                      {openEdit&&
                      <ColorButton variant="contained" onClick={()=>deleteBoothGroup(editGroupItem)} style={{marginRight: 10}}>Delete</ColorButton>
                      }
                      <Button variant="text" onClick={()=>{setOpenAdd(false); setOpenEdit(false)}}>Cancel</Button>
                    </Grid>
                  </Grid>
                </Collapse>
              </Box>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={3}>
            <SetupPrice refresh = {tabValue==3}/>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={4}>
            <SetupPremium refresh = {tabValue==4}/>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={5}>
            <SetupDiscount refresh = {tabValue==5}/>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={6}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon className='collapseIcon' />}
              defaultExpandIcon={<ChevronRightIcon className='collapseIcon'/>}
              expanded={expandedTree}
              // selected={selectedTree}
              onNodeToggle={handleToggleTree}
              // onNodeSelect={handleSelectTree}
              // multiSelect
            >
              {
                categoryData.SUBCAT.map((node) => RenderTreeWithCheckboxes(node))
              }
                
            </TreeView>
            <Box mt={2}>
              <Button variant="text" onClick={()=>{handleOpenAddCategoryItem()}}  startIcon={<AddIcon />}><b>Create Category Item</b></Button>
              <Collapse in={openAddCategoryItem || openEditCategoryItem}>
              
                <Paper elevation={3} style={{padding:'20px', marginTop:20}}>
                  {openAddCategoryItem&&
                  <h4 style={{marginTop:0}}>New Category Item</h4>
                  }
                  {openEditCategoryItem&&
                  <h4 style={{marginTop:0}}>Edit Category Item({editCategoryItem.TITLE})</h4>
                  }
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="name"
                        label="Title"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editCategoryItem.TITLE?editCategoryItem.TITLE:''}
                        onChange={(e)=>handleEditCategoryItemValue('TITLE', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>       
                    <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                      <Button variant="contained" onClick={saveCategoryItem} style={{marginRight: 10}}>Save</Button>                      
                      {openEditCategoryItem&&
                      <ColorButton variant="contained" onClick={()=>deleteCategoryItem(editCategoryItem)} style={{marginRight: 10}}>Delete</ColorButton>
                      }
                      <Button variant="text" onClick={()=>{setOpenAddCategoryItem(false); setOpenEditCategoryItem(false)}}>Cancel</Button>
                    </Grid>                         
                  </Grid>
                </Paper>
              </Collapse>
            </Box>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={7}>
            {/* <label style={{fontSize:14.5}}>Booth Number:{props.boothTexts[0]}</label>*/}
            <Box>
              <Table className="pricing-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>&nbsp;</TableCell> */}
                    <TableCell width="40%">Name</TableCell>
                    <TableCell width="10%" style={{textAlign:'center'}}>Order</TableCell>
                    <TableCell width="25%" style={{textAlign:'center'}}>Created at</TableCell>
                    <TableCell width="25%" style={{textAlign:'center'}}>Updated at</TableCell>
                    <TableCell width="25%" style={{textAlign:'center'}}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {boothFileList.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {/* <TableCell component="th" scope="row">
                        <input type='checkbox' checked={premiumCheck&& premiumCheck == row._id} onClick={()=>{setPremiumCheck(row._id)}}/>
                      </TableCell> */}
                      <TableCell>                      
                        <a href="javascript:void(0)" onClick={()=>handleOpenEditMapItem(row)}>{row.TITLE?row.TITLE:""}</a>
                      </TableCell>
                      <TableCell style={{textAlign:'center'}}>{row.DISPLAY_ORDER?row.DISPLAY_ORDER:0}</TableCell>
                      <TableCell style={{textAlign:'center'}}>{row.CREATE_DATE?moment(row.CREATE_DATE).format("MM/DD/YYYY HH:mm:ss"):''}</TableCell>
                      <TableCell style={{textAlign:'center'}}>{row.UPDATED?moment(row.UPDATED).format("MM/DD/YYYY HH:mm:ss"):''}</TableCell>
                      <TableCell style={{textAlign:'center'}}>
                        <IconButton
                          size="small"
                          edge="start"
                          aria-label="assign"
                          onClick={
                            ()=>{deleteMap(row.TITLE?row.TITLE:"", row['KEY_ID'])}
                          }
                        >
                          <DeleteIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box mt={2}>
              <Collapse in={openEditMapItem}>
              
                <Paper elevation={3} style={{padding:'20px', marginTop:20}}>
                  <h4 style={{marginTop:0}}>Edit Map</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="name"
                        label="Map name"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editMapItem.TITLE?editMapItem.TITLE:''}
                        onChange={(e)=>handleEditMapItemValue('TITLE', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                    
                    <Grid item xs={4}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="description"
                        label="Map order"
                        fullWidth
                        variant="outlined"
                        type="number"
                        size="small"
                        value={editMapItem.DISPLAY_ORDER?editMapItem.DISPLAY_ORDER:0}
                        onChange={(e)=>handleEditMapItemValue('DISPLAY_ORDER', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>  
                    <Grid item xs={6} > 
                      <Box mb={2}>
                        <label><b>Dwg file</b></label>
                        {
                          editMapSetup?.DWG?.DWG_NAME &&
                          <Box display='flex' style={{alignItems:'center'}}>
                            <p style={{margin:0}}>{editMapSetup?.DWG?.DWG_NAME}</p>                          
                            <IconButton  size="small" style={{marginLeft:10}}
                                onClick={(e)=>{
                                  onDeleteDwg(e)
                                }}
                            
                              ><DeleteForeverOutlinedIcon/>
                            </IconButton>
                            {editMapSetup?.DWG?.DWG_PATH &&
                            <IconButton  size="small" style={{marginLeft:10}}
                              download={editMapSetup?.DWG?.DWG_NAME}
                              href={editMapSetup?.DWG?.DWG_PATH}                            
                              ><FileDownloadIcon/>
                            </IconButton>
                            }
                          </Box>
                        }
                      </Box>  
                      <FormControl variant="outlined">                        
                        <input type="file" ref={dwgRef} onChange={(e)=>onChangeDwg(e)} accept=".dwg,.dxf"/>
                      </FormControl>                       
                    </Grid>  
                    <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                      <Button variant="contained" onClick={saveMapItem} style={{marginRight: 10}}>Save</Button>   
                      <Button variant="text" onClick={()=>{ setOpenEditMapItem(false)}}>Cancel</Button>
                    </Grid>                
                  </Grid>
                </Paper>
              </Collapse>
            </Box>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={8}>
            {/* <label style={{fontSize:14.5}}>Booth Number:{props.boothTexts[0]}</label>*/}
            
            <Box >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="XWebID"
                        label="XWebID"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={mechantAccount?.XWebID?mechantAccount?.XWebID:''}
                        onChange={(e)=>props.setMechantAccount({...mechantAccount, XWebID:e.target.value})}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> 
                    <Grid item xs={6}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="TID"
                        label="TID"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={mechantAccount?.TID?mechantAccount?.TID:''}
                        onChange={(e)=>props.setMechantAccount({...mechantAccount, TID:e.target.value})}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> 
                    <Grid item xs={6}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="AuthKey"
                        label="AuthKey"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={mechantAccount?.AuthKey?mechantAccount?.AuthKey:''}
                        onChange={(e)=>props.setMechantAccount({...mechantAccount, AuthKey:e.target.value})}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                    
                    <Grid item xs={6}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="Transactions API Key"
                        label="Transactions API Key"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={mechantAccount?.Transactions_API_Key?mechantAccount?.Transactions_API_Key:''}
                        onChange={(e)=>props.setMechantAccount({...mechantAccount, Transactions_API_Key:e.target.value})}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                    
                    <Grid item xs={6}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="Transactions API Secret Key"
                        label="Transactions API Secret Key"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={mechantAccount?.Transactions_API_Secret_Key?mechantAccount?.Transactions_API_Secret_Key:''}
                        onChange={(e)=>props.setMechantAccount({...mechantAccount, Transactions_API_Secret_Key:e.target.value})}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                    
                    <Grid item xs={6}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="PayFields API Key"
                        label="PayFields API Key"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={mechantAccount?.PayFields_API_Key?mechantAccount?.PayFields_API_Key:''}
                        onChange={(e)=>props.setMechantAccount({...mechantAccount, PayFields_API_Key:e.target.value})}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                                       
                    <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                      <Button variant="contained" onClick={saveMechantAccount} style={{marginRight: 10}}>Save</Button> 
                    </Grid>                
                  </Grid>
            </Box>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
        {openColorPicker &&        
          <ColorPicker 
            open={openColorPicker}
            onClose={(e)=>{setOpenColorPicker(false)}}
            height ={400}
            className="group-color-picker"
            color={ selectedColor }
            onChange={ (e)=>{handleChangeColorComplete(e); setOpenColorPicker(false)} }
            onChangeComplete={ (e)=>{handleChangeColorComplete(e); setOpenColorPicker(false)} }
          />
        }
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message={snackMsg}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
        <CropModal
            open={openCropModal}
            onClose={handleCloseCropModal}
            onOk={confirmCropModal}
            imgSrc={imgSrc}
            aspectRatio= {selectedCropItem == "logo"?375/63:3200/300}
            initialAspectRatio = {selectedCropItem == "logo"?375/63:3200/300}
            autoCropArea= {1}
        />
        <DeleteConfirm
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            onOk={confirmDeleteModal}
            title={deleteTitle}
        />
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setUnit : value=>dispatch(setUnit(value)),
        setBoothInfo:  value=>dispatch(setBoothInfo(value)),
        setLoading:  data=>dispatch(setLoading(data)),
        setGroupOptions:  data=>dispatch(setGroupOptions(data)),
        setPricingOptions:  data=>dispatch(setPricingOptions(data)),
        setPremiumOptions:  data=>dispatch(setPremiumOptions(data)),
        setDiscountOptions:  data=>dispatch(setDiscountOptions(data)),
        setCategoryOptions:  data=>dispatch(setCategoryOptions(data)),
        setFeeGroupOptions:  data=>dispatch(setFeeGroupOptions(data)),
        setActiveBoothFile: data=>dispatch(setActiveBoothFile(data)),  
        setBoothFileList: data=>dispatch(setBoothFileList(data)) ,
        setMechantAccount: data=>dispatch(setMechantAccount(data)),
        setFeeCategoryOptions:  data=>dispatch(setFeeCategoryOptions(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SetupModal);