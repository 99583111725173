import React ,{ useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SetAisleModal(props) {
  const { onClose, onOk, open, aisle } = props;
  var mainLayers = props.main.mainLayers;
  const [name, setName] = useState(false);

  useEffect(() => {
    if(open){
      var aisleName = "";
      var textObjs= aisle.getObjects('text');
      if(textObjs && textObjs.length>0){
        aisleName = textObjs[0].get('text');
        setName(aisleName)
      }
    }
  }, [open])
  
  const onChangeName = (e)=>{
    setName(e.target.value)
  }
 

  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'setaisle-dialog-title'}}
      aria-labelledby="setaisle-dialog-title" 
      open={open}>
      <DialogTitle id='setaisle-dialog-title' >Aisle</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        <TextField
          margin="dense"
          label="Aisle Name"
          type="name"
          fullWidth
          variant="standard"
          value={name}
          onChange={onChangeName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={(e)=>onOk(name)}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SetAisleModal);