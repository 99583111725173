import * as React from 'react';
import { fabric } from "fabric";
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit,  setLoading, setPricingOptions, setPremiumOptions, setDiscountOptions, setCategoryOptions,setFeeGroupOptions } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import rgbHex from "rgb-hex";
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, setObjectBG, resizeBooth, addText, px2unit, unit2Px} from '../../utils/CanvasTool'
import { uuid4 } from './sketch_main/utils';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { getDefaultGroup, containsObject } from '../../utils/Common'

import { parseToken, convertRgbHex } from '../../utils/Common'
import { getTokenCookie } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb:3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SetBoothModal(props) {
  
  let query = useQuery();
  const [snackMsg, setSnackMsg] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [activeObject, setActiveObject] = React.useState(null);
  const [priceCheck , setPriceCheck] = React.useState([]);
  const [boothName , setBoothName] = React.useState('');
  const [boothGroup , setBoothGroup] = React.useState('');
  const [tabValue, setTabValue] = React.useState(0);
  

  
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  var mainCanvas = props.main.mainCanvas
  const { onClose, onOk , open } = props;
  var mainLayers = props.main.mainLayers;
  
  // var pricingOptions = props.main.pricingOptions
  // var premiumOptions = props.main.premiumOptions
  // var discountOptions = props.main.discountOptions
  var groupOptions = props.main.groupOptions
  
  var activeFile;
  var boothFileList = props.main.boothFileList;
  for(var i = 0; i <boothFileList.length; i++){
      if(boothFileList[i]['active']){
          activeFile = boothFileList[i];
          break;
      }
  }

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }

  const onSet = ()=>{
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
    }
    if(activeObject && activeObject.type=="activeSelection"){
      var objects = activeObject.getObjects()
      for(var i = 0; i < objects.length; i++ ){
        objects[i].set({
          priceCheck: priceCheck,
          modified: true
        })
        var exhibitors = objects[i].exhibitors
        
        var bgColor = "#dadada"
        {
          for(var k = 0; k < groupOptions.length; k++){
              if(groupOptions[k].KEY_ID?.trim() == boothGroup?.trim()){
                  var groupOption = groupOptions[k]
                  if(objects[i].holdBooth == '1'){
                    bgColor = convertRgbHex(groupOption['RESERVED']);
                  }
                  else if(objects[i].sold == '1'){
                    bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                  }
                  else if((priceCheck && priceCheck.length > 0) ){
                      bgColor = convertRgbHex(groupOption['AVAILABLE']);
                  }
              }
          }
        }
        objects[i].set({        
          deselectedColor: bgColor
        })
        setObjectBG(mainCanvas, objects[i], bgColor)
      }
    }
    else if(activeObject && activeObject.class=="booth"){
      
      activeObject.set({
        priceCheck: priceCheck,
        modified: true
      })
      var exhibitors = activeObject.exhibitors
      mainCanvas.renderAll()
      var bgColor = "#dadada"
      {
        for(var k = 0; k < groupOptions.length; k++){
            if(groupOptions[k].KEY_ID?.trim() == boothGroup?.trim()){
                var groupOption = groupOptions[k]
                
                if(activeObject.holdBooth == '1'){
                  bgColor = convertRgbHex(groupOption['RESERVED']);
                }
                else if(activeObject.sold == '1'){
                  bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                }
                else if((priceCheck && priceCheck.length > 0)){
                    bgColor = convertRgbHex(groupOption['AVAILABLE']);
                }
            }
        }
      }
      activeObject.set({        
        deselectedColor: bgColor
      })
      setObjectBG(mainCanvas, activeObject, bgColor)
    }
    
    else if( activeObject.layer=="sponsorships"){
      
      activeObject.set({
        priceCheck: priceCheck,
        modified: true
      })
      var exhibitors = activeObject.exhibitors
      mainCanvas.renderAll()      
    }

    mainCanvas.renderAll()
    onOk(activeObject);
  }
  
  const handlePriceCheck = (pricingOption, row,  price_category)=>{
    var newPriceCheck
    if(priceCheck)
      newPriceCheck = [...priceCheck];
    else
      newPriceCheck = [];
    if(newPriceCheck.some(price => price.PRICE_KEY?.toLowerCase() === row.KEY_ID?.toLowerCase()))
      for( var i = 0; i < newPriceCheck.length; i++){ 
                                   
        if ( newPriceCheck[i].PRICE_KEY?.toLowerCase() === row.KEY_ID?.toLowerCase()) { 
          newPriceCheck.splice(i, 1); 
          i--; 
        }
        
      setPriceCheck(newPriceCheck)
    }
    else{
      var newItem = {
        PRODUCT_KEY: pricingOption.KEY_ID,
        PRICE: row.COLUMNS?.[0]?.PRICE,
        ACTIVE:1,
        PRICE_LABEL: `${pricingOption.TITLE} ${row.ROW_TITLE}`,
        PRICE_TYPE:row.COLUMNS?.[0]?.PRICE_TYPE,
        PRICE_CATEGORY:price_category,
        ROW_NO: row.ROW_NO,
        PRICE_KEY: row.KEY_ID,
      }
      setPriceCheck([...newPriceCheck, newItem])
    }
  }
  const fetchPricingOptions =async ()=>{
    var url = `${ESHOW_URL()}?method=getSales&product_type=sales&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getSales&product_type=sales&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            if(result.SALES){     
                var data =result.SALES 
                data.sort(function (a, b) {
                    return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                });
                props.setPricingOptions(data)
            }
        }
        else{
        }
    }).catch(error => {
    })  
  }
  const fetchPremiumOptions =async ()=>{
    var url = `${ESHOW_URL()}?method=getSales&product_type=Premiums&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getSales&product_type=Premiums&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            if(result.PREMIUMS){     
                data = result.PREMIUMS
                data.sort(function (a, b) {
                    return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                });
                props.setPremiumOptions(data)
            }
        }
        else{
        }
    }).catch(error => {
    })  
  }

  const fetchDiscountOptions =async ()=>{
    var url = `${ESHOW_URL()}?method=getSales&product_type=discounts&token=${getTokenCookie()}`
      var formdata = 
      {  
          query:`${ESHOW_URL()}?method=getSales&product_type=discounts&token=${getTokenCookie()}`                
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axiosInstance.get(url).then(res=>{
          const result = res.data;
          if(result.SUCCESS){  
              if(result.DISCOUNTS){     
                  data = result.DISCOUNTS
                  data.sort(function (a, b) {
                      return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                  });
                  props.setDiscountOptions(data)
              }
          }
          else{
          }
      }).catch(error => {
      })  
  }

  const fetchFeeGroupList =async ()=>{
    var url = `${ESHOW_URL()}?method=getFeeGroups&token=${getTokenCookie()}` 
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getFeeGroups&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        result.sort(function (a, b) {
            return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
        });
        props.setFeeGroupOptions(result)
    }).catch(error => {
    })  
}
  
  useEffect(() => {
    var mainCanvas = props.main.mainCanvas
    if(open && mainCanvas){      
      
      fetchPricingOptions()
      fetchPremiumOptions()
      fetchDiscountOptions()   
      fetchFeeGroupList() 
      var activeObject = mainCanvas.getActiveObject();

      if(activeObject.type == "group" && (activeObject.layer=="sponsorships" || activeObject.class=="booth" || activeObject.class=="booth-group")){
        var convertRateX= 1
        if(mainCanvas.convertRateX) {
            var convertRateX= Math.abs(mainCanvas.convertRateX)
        }
        setBoothName(activeObject.booth_number)
        var tmp2 = activeObject.get('priceCheck')
        if(tmp2){
          setPriceCheck(tmp2)
        }
        else{
          setPriceCheck([])
        }
        setBoothGroup(activeObject.boothGroup)
      }
      else if(activeObject.type == "activeSelection"){
        var objects = activeObject.getObjects()
        var selectionPrices = []
        var arr1 = [];
        for(var i = 0; i < objects.length; i++ ){
          if(objects[i].boothGroup){
            arr1.push(objects[i].boothGroup)
          }
          var tmp2 = objects[i].get('priceCheck')
          for(var j=0; j<tmp2?.length; j++){
            if(!containsObject(tmp2[j], selectionPrices, 'PRICE_KEY'))
            selectionPrices.push(tmp2[j])
          }
        }
        var mf = 1;
        var m = 0;
        var maxGroup
        for (var i=0; i<arr1.length; i++)
        {
                for (var j=i; j<arr1.length; j++)
                {
                        if (arr1[i] == arr1[j])
                        m++;
                        if (mf<m)
                        {
                          mf=m; 
                          maxGroup = arr1[i];
                        }
                }
                m=0;
        }
        setBoothGroup(maxGroup?maxGroup:null)

        setPriceCheck(selectionPrices)
      }
      else{        
        setBoothGroup(null)
        setPriceCheck([])
      }

      setActiveObject(activeObject)
    }
  }  , [open])
  
  const onChangeName = (e)=>{
    setBoothName(e.target.value)
  }
  const getPricesByGroup = (pricingOptions)=>{
    let pricesbyGroup = pricingOptions.reduce((r, a) => {
      r[a.DISPLAY_GROUP_NAME] = [...r[a.DISPLAY_GROUP_NAME] || [], a];
      return r;
     }, {});
    
    var output = [];
    for(var display_group in pricesbyGroup){
      var items = pricesbyGroup[display_group];
      output.push({display_group, items})
    }
    output.sort(function(a, b) {
      const nameA = a.display_group; // ignore upper and lowercase
      const nameB = b.display_group; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
    //console.log("groupBySize", output)
    return output;
  }

  // //console.log("boothGroup", boothGroup)
  var pricingOptions = [];
  if(props.main.pricingOptions){
    pricingOptions = [...props.main.pricingOptions]
    //console.log("pricingOptions", pricingOptions)
    for(var i = 0; i < pricingOptions.length; i++){
      pricingOptions[i]['DISPLAY_GROUP_NAME'] = '';
      for(var j = 0; j < props.main.feeGroupOptions?.length; j++){
        if(pricingOptions[i].DISPLAY_GROUP == props.main.feeGroupOptions[j].KEY_ID){
          pricingOptions[i]['DISPLAY_GROUP_NAME'] = props.main.feeGroupOptions[j].TITLE
        }
      }
    }
    pricingOptions = pricingOptions.sort(function (a, b) {
      if((a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 == (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1){
        return (a.DISPLAY_GROUP_NAME?a.DISPLAY_GROUP_NAME:'') - (b.DISPLAY_GROUP_NAME?b.DISPLAY_GROUP_NAME:'');
      }
      else
        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
    });   
  }

  var premiumOptions = [];
  if(props.main.premiumOptions){
    premiumOptions = [...props.main.premiumOptions]
    //console.log("pricingOptions", premiumOptions)
    for(var i = 0; i < premiumOptions.length; i++){
      premiumOptions[i]['DISPLAY_GROUP_NAME'] = '';
      for(var j = 0; j < props.main.feeGroupOptions?.length; j++){
        if(premiumOptions[i].DISPLAY_GROUP == props.main.feeGroupOptions[j].KEY_ID){
          premiumOptions[i]['DISPLAY_GROUP_NAME'] = props.main.feeGroupOptions[j].TITLE
        }
      }
    }
    premiumOptions = premiumOptions.sort(function (a, b) {
      if((a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 == (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1){
        return (a.DISPLAY_GROUP_NAME?a.DISPLAY_GROUP_NAME:'') - (b.DISPLAY_GROUP_NAME?b.DISPLAY_GROUP_NAME:'');
      }
      else
        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
    });   
  }

  var discountOptions = [];
  if(props.main.discountOptions){
    discountOptions = [...props.main.discountOptions]
    //console.log("pricingOptions", pricingOptions)
    for(var i = 0; i < discountOptions.length; i++){
      discountOptions[i]['DISPLAY_GROUP_NAME'] = '';
      for(var j = 0; j < props.main.feeGroupOptions?.length; j++){
        if(discountOptions[i].DISPLAY_GROUP == props.main.feeGroupOptions[j].KEY_ID){
          discountOptions[i]['DISPLAY_GROUP_NAME'] = props.main.feeGroupOptions[j].TITLE
        }
      }
    }
    discountOptions = discountOptions.sort(function (a, b) {
      if((a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 == (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1){
        return (a.DISPLAY_GROUP_NAME?a.DISPLAY_GROUP_NAME:'') - (b.DISPLAY_GROUP_NAME?b.DISPLAY_GROUP_NAME:'');
      }
      else
        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
    });   
  }
  var pricingOptionsByGroup = getPricesByGroup(pricingOptions)
  var premiumOptionsByGroup = getPricesByGroup(premiumOptions)
  var discountOptionsByGroup = getPricesByGroup(discountOptions)
  
  //console.log("pricingOptions", pricingOptionsByGroup)
  //console.log("premiumOptions", premiumOptionsByGroup)
  //console.log("discountOptions", discountOptionsByGroup)
  
  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'boothprice-dialog-title'}}
      aria-labelledby="boothprice-dialog-title"
      fullWidth={true}
      maxWidth={"md"} 
      open={open}
    >
      <DialogTitle id='boothprice-dialog-title'>Booth Pricing</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label={<b>Price</b>} {...a11yProps(0)} />
            <Tab label={<b>Premium Price</b>} {...a11yProps(1)} />
            <Tab label={<b>Discount</b>} {...a11yProps(2)} />
          </Tabs>
        </Box>
        
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2} >  
            <Grid item xs={12}>
              <Table className="pricing-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell width="70%">Title</TableCell>
                    {/* <TableCell width="40%">Description</TableCell> */}
                    <TableCell>Price</TableCell>
                    <TableCell>Price type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {pricingOptionsByGroup.map((pricingOptions, g_Index) => (
                <>
                  <TableRow key={g_Index}>
                    <TableCell colSpan='4' style={{textAlign:'left', padding:'10px 18px', borderBottom:'1px solid #e0e0e0'}}><b>{pricingOptions.display_group}</b></TableCell>
                  </TableRow>
                  {pricingOptions.items.map((pricingOption, index) => (
                    <>
                    {pricingOption?.ROWS?.map((row, row_index) =>(
                      <TableRow
                        key={`${index}${row_index}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <input type='checkbox' checked={row.KEY_ID && priceCheck && priceCheck.some(price => price.PRICE_KEY?.toLowerCase() === row.KEY_ID?.toLowerCase())} onClick={()=>{handlePriceCheck(pricingOption, row, 'price')}}/>
                        </TableCell>
                        <TableCell>{pricingOption.TITLE} {row.ROW_TITLE}</TableCell>
                        {/* <TableCell>{pricingOption.DESCRIPTION}</TableCell> */}
                        <TableCell>{row.COLUMNS?.[0]?.PRICE}</TableCell>
                        <TableCell>{row.COLUMNS?.[0]?.PRICE_TYPE == 'sq_ft'?`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`:row.COLUMNS?.[0]?.PRICE_TYPE}</TableCell>
                      </TableRow>
                      
                    ))}
                    </>
                  ))}
                </>
                ))}
                </TableBody>
              </Table>
            </Grid>   
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2} > 
          <Grid item xs={12}>
              <Table className="pricing-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell width="70%">Title</TableCell>
                    {/* <TableCell width="40%">Description</TableCell> */}
                    <TableCell>Price</TableCell>
                    <TableCell>Price type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {premiumOptionsByGroup.map((premiumOptions, g_Index) => (
                <>
                  <TableRow key={g_Index}>
                    <TableCell colSpan='4' style={{textAlign:'left', padding:'10px 18px', borderBottom:'1px solid #e0e0e0'}}><b>{premiumOptions.display_group}</b></TableCell>
                  </TableRow>
                    {premiumOptions.items.map((pricingOption, index) => (
                      <>
                      {pricingOption?.ROWS?.map((row, row_index) =>(
                        <TableRow
                          key={`${index}${row_index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <input type='checkbox' checked={row.KEY_ID && priceCheck && priceCheck.some(price => price.PRICE_KEY?.toLowerCase() === row.KEY_ID?.toLowerCase())} onClick={()=>{handlePriceCheck(pricingOption, row, 'premium')}}/>
                          </TableCell>
                          <TableCell>{pricingOption.TITLE} {row.ROW_TITLE}</TableCell>
                          {/* <TableCell>{pricingOption.DESCRIPTION}</TableCell> */}
                          <TableCell>{row.COLUMNS?.[0]?.PRICE}</TableCell>
                          <TableCell>{row.COLUMNS?.[0]?.PRICE_TYPE == 'sq_ft'?`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`:row.COLUMNS?.[0]?.PRICE_TYPE}</TableCell>
                        </TableRow>
                      ))}
                      </>
                    ))}
                </>
                ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Grid container spacing={2} >     
            <Grid item xs={12}>
              <Table className="pricing-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell width="70%">Title</TableCell>
                    {/* <TableCell width="40%">Description</TableCell> */}
                    <TableCell>Price</TableCell>
                    <TableCell>Price type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {discountOptionsByGroup.map((discountOptions, g_Index) => (
                <>
                  <TableRow key={g_Index}>
                    <TableCell colSpan='4' style={{textAlign:'left', padding:'10px 18px', borderBottom:'1px solid #e0e0e0'}}><b>{discountOptions.display_group}</b></TableCell>
                  </TableRow>
                    {discountOptions.items.map((pricingOption, index) => (
                      <>
                      {pricingOption?.ROWS?.map((row, row_index) =>(
                        <TableRow
                          key={`${index}${row_index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <input type='checkbox' checked={row.KEY_ID && priceCheck && priceCheck.some(price => price.PRICE_KEY?.toLowerCase() === row.KEY_ID?.toLowerCase())} onClick={()=>{handlePriceCheck(pricingOption, row, 'discount')}}/>
                          </TableCell>
                          <TableCell>{pricingOption.TITLE} {row.ROW_TITLE}</TableCell>
                          {/* <TableCell>{pricingOption.DESCRIPTION}</TableCell> */}
                          <TableCell>{row.COLUMNS?.[0]?.PRICE}</TableCell>
                          <TableCell>{row.COLUMNS?.[0]?.PRICE_TYPE == 'sq_ft'?`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`:row.COLUMNS?.[0]?.PRICE_TYPE}</TableCell>
                        </TableRow>
                      ))}
                      </>
                    ))}
                </>
                ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSet}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={snackMsg}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      />
    </Dialog>
    
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setLoading:  data=>dispatch(setLoading(data)),
        setPricingOptions:  data=>dispatch(setPricingOptions(data)),
        setPremiumOptions:  data=>dispatch(setPremiumOptions(data)),
        setDiscountOptions:  data=>dispatch(setDiscountOptions(data)),
        setCategoryOptions:  data=>dispatch(setCategoryOptions(data)),
        setFeeGroupOptions:  data=>dispatch(setFeeGroupOptions(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SetBoothModal);